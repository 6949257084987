import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TextField from 'components/Forms/TextField';
import styled from 'styled-components/macro';
import {CHANGE_PASSWORD} from '../graphql';
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress } from '@material-ui/core';
import { theme } from 'styled-tools';
import { useAuthToken } from 'config/auth';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiFormControl-root {
    margin-bottom: 1rem;
    width: 100%;
  }

  .MuiAlert-root,
  .MuiFormControlLabel-root {
    margin-bottom: 1rem;
  }

  .MuiFormControlLabel-root {
    align-self: flex-start;
  }

  .MuiInputBase-root {
    background-color: ${theme('colors.lightGrey')};

    .MuiInputAdornment-positionStart {
      margin-right: 1rem;
      color: #9d9d9d;
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
      border-color: ${theme('colors.lightGrey')};
    }
  }

  .MuiTextField-root {
    input {
      background-color: ${theme('colors.white')};
      padding-left: 1rem;
    }

    label {
      display: none;
    }
  }

  .MuiButton-root {
    min-width: 8rem;
  }
`;

function ChangePasswordForm () {
  const { resetToken } = useParams<{ resetToken: string }>();
  const history = useHistory();
  const [alert, setAlert] = useState('');
  const [, setAuthToken] = useAuthToken();
  let variables = { resetToken: resetToken, plainPassword: '', plainPasswordConfirm: ''};

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: data => {
      setAuthToken(data.changePassword.token);
      history.push('/');
    },
    onError: err => {
      setAlert('Invalid input: ' + err.message);
    },
  });

  return (
    <Formik
      initialValues={{ plainPassword: '', plainPasswordConfirm: '' }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          variables.plainPassword = values.plainPassword;
          variables.plainPasswordConfirm = values.plainPasswordConfirm;

          await changePassword({ variables: variables });

          setSubmitting(false);
        } catch (err) {
          setSubmitting(false);
        }
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledForm>

          {alert && <Alert severity='error'>{alert}</Alert>}

          <Field
            component={TextField}
            type="password"
            name="plainPassword"
            label='New Password'
            placeholder='New Password'
            variant='outlined'
            InputProps={{
              notched: false,
              startAdornment: (
                <InputAdornment position="start" variant="standard">
                  <LockOutlinedIcon />
                </InputAdornment>
              )
            }}
            InputLabelProps={{
              disableAnimation: true,
            }}
          />
          <Field
            component={TextField}
            type="password"
            name="plainPasswordConfirm"
            label='Confirm New Password'
            placeholder='Confirm New Password'
            variant='outlined'
            InputProps={{
              notched: false,
              startAdornment: (
                <InputAdornment position="start" variant="standard">
                  <LockOutlinedIcon />
                </InputAdornment>
              )
            }}
            InputLabelProps={{
              disableAnimation: true,
            }}
          />

          {isSubmitting && <LinearProgress />}
          <Button
            disabled={isSubmitting}
            onClick={submitForm}
            type='submit'
          >
            Change Password
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
}

export default ChangePasswordForm;
