import React, { FunctionComponent } from 'react';
import { formatPhoneNumber, parsePhoneNumber, ParsePhoneNumberOptions } from 'react-phone-number-input';

interface OwnProps {
  value: string;
  options?: ParsePhoneNumberOptions;
}

type Props = OwnProps;

const PhoneNumber: FunctionComponent<Props> = ({ value, options }) => {
  const phoneNumber = parsePhoneNumber(value, { defaultCountry: 'US', ...(options || {}) });

  return <span>{phoneNumber?.number ? formatPhoneNumber(phoneNumber?.number) : value}</span>;
};

export default PhoneNumber;
