import React, { FunctionComponent } from 'react';
import { JobStatus } from 'types';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components/macro';

const ADDT_NOTES = 'Additional Notes'

const NotesGrid = styled(Grid)`
  height: 2.2rem;
  overflow: hidden;
`;

interface OwnProps {
  status: JobStatus;
  notes?: string;
}

type Props = OwnProps;

const JobStatusIcon: FunctionComponent<Props> = ({ status, notes }: Props) => {
  if (!status.iconCode) {
    console.error(`No iconCode provided for status ${status.name} (${status.id})`);
    return null;
  }

  let tooltipContent = status.name;
  if (status.name.includes(ADDT_NOTES)) {
    tooltipContent = <NotesGrid item xs>{notes}</NotesGrid> as any
  }

  return (
    <Tooltip title={tooltipContent} arrow>
      <Icon className={status.iconExtraClasses}>{status.iconCode}</Icon>
    </Tooltip>
  );
};

export default JobStatusIcon;
