import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import withScrolling from 'react-dnd-scrolling';
import { useLocation } from 'react-router-dom';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import { useCalendarContext } from 'contexts/CalendarContext';

const StyledScrollingWrapper = styled('div')`
  overflow: auto;
  height: 100%;

  ${ifNotProp('isLogin', css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: ${theme('headerOffset')};
    padding-bottom: ${ifProp('$disclaimerClosed', '0', '11.650625rem')};

    ${props => props.theme.breakpoints.up('md')} {
      padding-bottom: 5.216875rem;
    }
  `, css`
    padding-bottom: ${ifProp('$disclaimerClosed', '0', '11.650625rem')};

    ${props => props.theme.breakpoints.up('md')} {
      padding-bottom: 0;
    }
  `)}
`;

const ScrollingComponent = withScrolling(StyledScrollingWrapper);

function ScrollingContainer({ children }: { children: ReactNode}) {
  const location = useLocation();
  const { disclaimerClosed } = useCalendarContext();

  return (
    <ScrollingComponent isLogin={location.pathname === '/login'} $disclaimerClosed={disclaimerClosed}>
      {children}
    </ScrollingComponent>
  );
}

export default ScrollingContainer;
