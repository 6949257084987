import React, { useState, useContext, ChangeEvent, ChangeEventHandler } from 'react';
import CalendarResourceInfoSection from './CalendarResourceInfoSection';
import CreateEventForm from './CreateEventForm';
import EventCalendarResourceField from 'components/Forms/TypeAheadField/EventCalendarResourceField';
import styled from 'styled-components/macro';
import { Button, Typography } from '@material-ui/core';
import { CalendarContext, CalendarDispatchContext } from 'contexts/CalendarContext';
import { CalendarResource, Event } from 'types';
import { CREATE_EVENTS } from './CreateEventForm/graphql';
import { CreateJobContext } from 'contexts/ScheduleJobsContext';
import { luxonDT } from 'utils/dates';
import { eventInDateRange, eventMatchesFilters } from 'utils/jobUtils';
import { theme } from 'styled-tools';
import { useFormik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { setCacheUpdate } from 'contexts/CalendarContext/reducer';
import { useSearchState } from '../Forms/TypeAheadField';

interface ScheduleEventProps {
  toggleSlideOut: Function,
}

interface ScheduleEventSectionProps {
  justifyContent?: string,
}

const ScheduleEventContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4.3125rem;

  ${props => props.theme.breakpoints.down('sm')} {
    padding-bottom: 5.5rem;
  }
`;

const ScheduleEventHeader = styled.div`
  padding: 1rem;
  box-shadow: ${theme('boxShadow.light')};
  background: ${theme('colors.white')};
`;

export const ScheduleEventSection = styled.div<ScheduleEventSectionProps>`
  display: flex;
  padding: 0.5rem 1rem;
  width: 100%;
  border-bottom: 1px solid ${theme('colors.grey')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
`;

const CalendarResourceWrapper = styled.div`
  display: flex;
  flex-basis: 55%;
  margin-right: 0.75rem;
`;

const ActionContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${theme('colors.grey')};
  border-bottom: 1px solid ${theme('colors.grey')};
  justify-content: flex-end;
  padding: 1rem;
  background-color: ${theme('colors.white')};

  button:first-of-type {
    margin-right:0.5rem;
  }
  button:last-of-type {
    margin-left:0.5rem;
  }
`;

const ErrorMessage = styled.p`
  color: ${theme('colors.red')};
  margin: 0;
  margin-top: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.25rem;
`;

interface EventInput {
  name: string,
  startDate?: string,
  endDate?: string,
  notes?: string,
  calendarResource: number,
  recurring?: string,
  isRecurring?: boolean,
  allResourcesEvent: boolean,
}

function ScheduleEvent({ toggleSlideOut }: ScheduleEventProps) {
  const { searchFilter, searchValue, handleOptionSelected, onSearchChange, setSearchValue, setSearchFilter } = useSearchState('');
  const { jobTypes } = useContext(CreateJobContext);
  const state = useContext(CalendarContext);
  const dispatch = useContext(CalendarDispatchContext);
  const { startDate, endDate, filters } = state;

  const installJobType = jobTypes.find(jobType => jobType.name === 'Install');
  const warrantyJobType = jobTypes.find(jobType => jobType.name === 'Warranty');
  const [alert, setAlert] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [calendarResource, setCalendarResource] = useState<CalendarResource|undefined>(undefined);
  const [createEvents] = useMutation(CREATE_EVENTS, {
    onError: (err) => {
      setIsSubmitting(false);
      setAlert('Invalid Input: Please update the form and try again.');
    },
    update: (cache, { data: { createEvents } }) => {
      setIsSubmitting(false);

      function createCacheFragment(event: Event) {
        return cache.writeFragment({
          data: event,
          fragment: gql`
            fragment NewEvent on Event {
              id
              name
              startDate
              endDate
              recurring
              notes
              allResourcesEvent
              createdAt
              updatedAt
              calendarResource {
                id
                name
                jobType {
                  id
                  name
                }
                trade {
                  id
                  name
                }
              }
              createdBy {
                id
                email
                firstName
                lastName
              }
              updatedBy {
                id
                email
                firstName
                lastName
              }
            }
          `
        });
      }

      const filteredEvents = createEvents.events.filter((event: Event) =>
        eventMatchesFilters(event, startDate, endDate, filters, installJobType, warrantyJobType)
      ).map(createCacheFragment);

      const filteredAllResourceEvents = createEvents.events.filter((event: Event) =>
        eventInDateRange(event, startDate, endDate) && event.allResourcesEvent
      ).map(createCacheFragment);

      if (filteredEvents.length > 0 || filteredAllResourceEvents.length > 0) {
        dispatch(setCacheUpdate(true));
      }

      cache.modify({
        fields: {
          searchEvents: (existingEvents = []) => [...existingEvents, ...filteredEvents],
          searchAllResourcesEvents: (existing = []) => [...existing, ...filteredAllResourceEvents],
        }
      });
    }
  });

  const handleFinishedScheduling = () => {
    toggleSlideOut && toggleSlideOut();
    setCalendarResource(undefined);
    setIsSubmitting(false);
    formik.setFieldValue('events', []);
    setSearchValue('');
    setSearchFilter('');
  }

  const scheduleNewEvent = () => {
    let existingEvents: EventInput[] = [...formik.values.events];

    if (calendarResource) {
      const newEvent: EventInput = {
        name: '',
        calendarResource: calendarResource?.id,
        isRecurring: false,
        allResourcesEvent: false,
        startDate: undefined,
        endDate: undefined
      };

      existingEvents.push(newEvent);
      formik.setFieldValue('events', existingEvents);
    }
  }

  const updateFormikValue = (fieldName: string, index: number, value: any) => {
    formik.setFieldValue(`events[${index}].${fieldName}`, value);
  }

  const handleFormikChange: ChangeEventHandler = (event: ChangeEvent) => {
    formik.handleChange(event);
  }

  const cancelEvent = (index: number) => {
    let existingEvents: EventInput[] = [...formik.values.events];
    existingEvents.splice(index, 1);
    formik.setFieldValue('events', existingEvents);
  }

  const formik = useFormik({
    initialValues: { events: [] },
    onSubmit: async (values: any, {resetForm}) => {
      setIsSubmitting(true);
      let events: EventInput[] = [];

      values.events.forEach((event: EventInput) => {
        delete event.isRecurring;
        events.push({
          ...event,
          startDate: luxonDT(event.startDate).startOf('day').toUTC().toISO(),
          endDate: luxonDT(event.endDate ?? event.startDate).startOf('day').toUTC().toISO(),
        });
      });

      if (events.length > 0) {
        const parsedValues = { input: events };
        const { data, errors } = await createEvents({ variables: parsedValues });
        const mutationRes = data.createEvents;

        if (mutationRes.success) {
          resetForm({ values: { events: [] } });
          setAlert('');
          handleFinishedScheduling();
        } else {
          setAlert('Invalid input:' + errors);
        }
      }

      setIsSubmitting(false);
    },
  });

  return (
    <ScheduleEventContainer>
      <ScheduleEventHeader>
        <Typography variant="h4">Select Calendar Resource</Typography>
      </ScheduleEventHeader>
      <ScheduleEventSection>
        <CalendarResourceWrapper>
          <EventCalendarResourceField
            searchFilter={searchFilter}
            searchValue={searchValue}
            handleOptionSelected={handleOptionSelected}
            onSearchChange={onSearchChange}
            setCalendarResource={setCalendarResource}
          />
        </CalendarResourceWrapper>
        {calendarResource &&
          <CalendarResourceInfoSection calendarResource={calendarResource} />
        }
      </ScheduleEventSection>
      {calendarResource &&
        <form onSubmit={formik.handleSubmit} autoComplete='off'>
          {alert && <ErrorMessage>{alert}</ErrorMessage>}
          {formik.values.events.map((value: Event, index: number) => (
            <ScheduleEventSection key={`schedule_event_${index}`}>
              <CreateEventForm
                index={index}
                event={value}
                onCancel={cancelEvent}
                onFieldUpdate={updateFormikValue}
                onFieldChange={handleFormikChange}
              />
            </ScheduleEventSection>
          ))}
          <ScheduleEventSection>
            <Button
              onClick={scheduleNewEvent}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Add Installer Note for this Calendar Resource
            </Button>
          </ScheduleEventSection>
          <ActionContainer>
            <Button variant='text' onClick={handleFinishedScheduling}>CANCEL THESE NOTES</Button>
            <Button variant='contained' color='secondary' type='submit' disabled={isSubmitting}>FINISHED SCHEDULING</Button>
          </ActionContainer>
        </form>
      }
    </ScheduleEventContainer>
  );
}

export default ScheduleEvent;
