import { useQuery, gql } from "@apollo/client";

const GET_TRADES = gql`
  query getTrades {
    listTrades {
      id
      name
      jobTypes {
        id
        name
      }
      jobAttributes {
        id
        label
        required
        fieldType
        fieldOptions
        readOnly
        adminOnly
        specialBehavior
        specialBehaviorOptions
        restrictedPermission {
          id
          slug
        }
        editOnlyPermission {
          id
          slug
        }
        jobTypes {
          id
          name
        }
      }
    }
  }
`;

function useGetTrades(options?: any) {
  return useQuery(GET_TRADES, options);
}

export default useGetTrades;
