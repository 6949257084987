import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import TextField from 'components/Forms/TextField';
import { Button, LinearProgress } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Alert from '@material-ui/lab/Alert';
import { theme } from 'styled-tools';
import { FORGOT_PASSWORD } from '../graphql';
import { useMutation } from '@apollo/client';

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiFormControl-root {
    margin-bottom: 1rem;
    width: 100%;
  }

  .MuiAlert-root,
  .MuiFormControlLabel-root {
    margin-bottom: 1rem;
  }

  .MuiFormControlLabel-root {
    align-self: flex-start;
  }

  .MuiInputBase-root {
    background-color: ${theme('colors.lightGrey')};

    .MuiInputAdornment-positionStart {
      margin-right: 1rem;
      color: #9d9d9d;
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
      border-color: ${theme('colors.lightGrey')};
    }
  }

  .MuiTextField-root {
    input {
      background-color: ${theme('colors.white')};
      padding-left: 1rem;
    }

    label {
      display: none;
    }
  }

  .MuiButton-root {
    min-width: 8rem;
  }
`;

interface Values {
  email: string;
}

function ForgotPasswordEmailForm () {
  const [alert, setAlert] = useState('');
  const [success, setSuccess] = useState('');
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  return (
    <Formik
      initialValues={{ email: '' }}
      validate={values => {
        const errors: Partial<Values> = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data } = await forgotPassword({ variables: { email: values.email} });
          const mutationRes = data.forgotPassword;

          if (mutationRes.success) {
            setSuccess('Reset request submitted, please check your email')
          } else {
            setSubmitting(false);
            setAlert('Invalid input: ' + mutationRes.errors[0].msg);
          }
        } catch (err) {
          setSubmitting(false);
        }
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledForm>

          {success && <Alert>{success}</Alert>}
          {alert && <Alert severity='error'>{alert}</Alert>}

          <p>Enter your email address and we'll send a code to your email.</p>
          <Field
            disabled={isSubmitting || (success !== '')}
            component={TextField}
            type='email'
            name='email'
            label='Email'
            placeholder='Email Address'
            variant='outlined'
            InputProps={{
              notched: false,
              startAdornment: (
                <InputAdornment position="start" variant="standard">
                  <MailOutlineIcon />
                </InputAdornment>
              )
            }}
            InputLabelProps={{
              disableAnimation: true,
            }}
          />

          {isSubmitting && <LinearProgress />}
          <Button
            disabled={isSubmitting || (success !== '')}
            onClick={submitForm}
            type='submit'
          >
            Reset Password
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
}

export default ForgotPasswordEmailForm;
