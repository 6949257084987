import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useAuthData, useAuthToken, useIsAuthenticated } from 'config/auth';
import Header from '../Header';
import styled from 'styled-components/macro';
import ScheduleEventSlideOut from 'components/SlideOut/ScheduleEventSlideOut';
import ScheduleJobSlideOut from 'components/SlideOut/ScheduleJobSlideOut';
import { useHistory } from 'react-router-dom';
import Footer from 'components/Footer/Footer';

interface LayoutProps {
  children: React.ReactNode,
  title?: string
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1 0 70%;
  max-width: 100%;
`;

interface ReferrerLocationState {
  from?: string;
}

function Layout({ children, title = "CFC Calendar" }: LayoutProps) {
  const isAuthenticated = useIsAuthenticated();
  const [jobSlideOutOpen, setJobSlideOutOpen] = React.useState(false);
  const [eventSlideOutOpen, setEventSlideOutOpen] = React.useState(false);
  const [authToken,,removeAuthToken] = useAuthToken();
  const authData = useAuthData();
  const history = useHistory();

  useEffect(() => {
    const windowHref = window.location.href;

    if (authToken && authData?.exp) {
      const newLogoutInterval = setInterval(() => {
        const now = new Date().getTime() / 1000;
        if (authData?.exp && authData.exp < now) {
          removeAuthToken();
          history.push('/login');
        }
      }, 60 * 1000);

      return () => {
        clearInterval(newLogoutInterval);
      };
    } else if (!authToken && !windowHref.includes('/login') && !windowHref.includes('/forgot-password')) {
      const locationState: ReferrerLocationState|any = history.location.state;
      if (!locationState || (locationState.from && locationState.from !== '/login')) {
        history.push('/login');
      }
    }
  }, [removeAuthToken, authToken, authData, history]);

  const toggleJobSlideOut = () => {
    if (eventSlideOutOpen && !jobSlideOutOpen) {
      setEventSlideOutOpen(false);
    }

    setJobSlideOutOpen(!jobSlideOutOpen);
  }
  const toggleEventSlideOut = () => {
    if (jobSlideOutOpen && !eventSlideOutOpen) {
      setJobSlideOutOpen(false);
    }

    setEventSlideOutOpen(!eventSlideOutOpen);
  }

  return (
    <>
      <CssBaseline />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isAuthenticated ? (
        <>
          <Header toggleJobSlideOut={toggleJobSlideOut} toggleEventSlideOut={toggleEventSlideOut} />
          <ContentContainer>
            <ScheduleJobSlideOut slideOutOpen={jobSlideOutOpen} toggleSlideOut={toggleJobSlideOut} />
            <ScheduleEventSlideOut slideOutOpen={eventSlideOutOpen} toggleSlideOut={toggleEventSlideOut} />
            <Content id='content-root'>{children}</Content>
          </ContentContainer>
          <Footer />
        </>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default Layout;
