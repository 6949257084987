import { ReactComponent as search } from './icon-search.svg';
import { ReactComponent as plus } from './icon-plus.svg';
import { ReactComponent as remove } from './icon-remove.svg';
import { ReactComponent as chevronUp } from './icon-chevron-up.svg';
import { ReactComponent as chevronRight } from './icon-chevron-right.svg';
import { ReactComponent as chevronLeft } from './icon-chevron-left.svg';
import { ReactComponent as chevronDown } from './icon-chevron-down.svg';

export interface iconsInterface {
  search: any,
  plus: any,
  remove: any,
  chevronUp: any,
  chevronDown: any,
  chevronLeft: any,
  chevronRight: any,
}

const icons: iconsInterface = {
  search,
  plus,
  remove,
  chevronUp,
  chevronDown,
  chevronLeft,
  chevronRight,
}

export default icons;
