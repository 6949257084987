import styled from 'styled-components/macro';
import { TextareaAutosize } from "@material-ui/core";
import { theme } from "styled-tools";

const TextArea = styled(TextareaAutosize)`
  padding: 0.5rem;
  color: ${theme('colors.darkBlue')};
  font-weight: bold;
  border: solid 1px ${theme('colors.lightGrey')};
  border-radius: 4px;
  width: 100%;
  resize: vertical;
`;

export default TextArea;
