import React, { createContext, useState } from 'react';
import { useLazyQuery } from "@apollo/client";
import useGetTrades from 'hooks/useGetTrades';
import useGetWarehouses from 'hooks/useGetWarehouses';
import useGetJobTypes from 'hooks/useGetJobTypes';
import { JobStatus, JobType, PropertyAddress, Trade, Warehouse } from 'types';
import { GET_PROPERTY_ADDRESS } from './graphql';
import useGetJobStatuses from "hooks/useGetJobStatuses";

interface CreateJobState {
  address?: PropertyAddress,
  setAddress?: Function,
  fetchJobs: Function,
  clearData: Function,
  jobTypes: JobType[],
  trades: Trade[],
  warehouses: Warehouse[],
  jobStatuses: JobStatus[],
}

interface CreateJobProviderProps {
  children: React.ReactNode,
}

const defaultState: CreateJobState = {
  fetchJobs: function() {},
  clearData: function() {},
  jobTypes: [],
  trades: [],
  warehouses: [],
  jobStatuses: [],
}

export const CreateJobContext = createContext(defaultState);

export function CreateJobProvider({ children }: CreateJobProviderProps) {
  const [address, setAddress] = useState<PropertyAddress>();
  const [jobTypes, setJobTypes] = useState<JobType[]>([]);
  const [trades, setTrades] = useState<Trade[]>([]);
  const [warehouses, setWarehouses] = useState<Trade[]>([]);
  const [jobStatuses, setJobStatuses] = useState<JobStatus[]>([]);

  useGetTrades({
    onCompleted: (data: any) => setTrades(data?.listTrades ?? []),
  });
  useGetWarehouses({
    onCompleted: (data: any) => setWarehouses(data?.listWarehouses ?? []),
  });
  useGetJobTypes({
    onCompleted: (data: any) => setJobTypes(data?.listJobTypes ?? []),
  });
  useGetJobStatuses({
    onCompleted: (data: any) => setJobStatuses(data?.listJobStatuses ?? []),
  });

  const [fetchAddressInfo] = useLazyQuery(GET_PROPERTY_ADDRESS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getPropertyAddress }: any) => {
      setAddress(getPropertyAddress);
    },
  });

  const selectAddress = (id: number) => {
    fetchAddressInfo({ variables: { id } });
  }

  const fetchJobs = () => {
    if (address) {
      fetchAddressInfo({ variables: { id: address.id } });
    }
  }

  const clearData = () => {
    setAddress(undefined);
  }

  return <CreateJobContext.Provider value={{
    address,
    setAddress: selectAddress,
    fetchJobs,
    clearData,
    jobTypes,
    trades,
    warehouses,
    jobStatuses,
  }}>{children}</CreateJobContext.Provider>;
}

export const CreateJobConsumer = CreateJobContext.Consumer;
