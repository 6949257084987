import { gql } from '@apollo/client';

export const CalendarEventFields = gql`
  fragment CalendarEventFields on Event {
    id
    name
    startDate
    endDate
    recurring
    notes
    allResourcesEvent
    createdAt
    updatedAt
    calendarResource {
      id
      name
      jobType {
        id
        name
      }
      trade {
        id
        name
      }
    }
    createdBy {
      id
      email
      firstName
      lastName
    }
    updatedBy {
      id
      email
      firstName
      lastName
    }
  }
`;
