import React, { FunctionComponent, useMemo } from 'react';
import { JobAttributeValue } from 'types';
import styled, { css } from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import sortBy from 'lodash.sortby';
import { deserializeAttributeValue, filterEmptyJobAttributes, displayJobAttribute } from 'utils/jobUtils';
import { VIEW_MODES } from 'contexts/CalendarContext';
import { useCanAdminOnlyJobAttributes } from 'config/auth';

const Wrapper = styled.div<{ $viewMode?: string }>`
  font-size: 0.9rem;

  ${({ $viewMode }) => $viewMode === VIEW_MODES.TEN_DAY && css`
    flex-wrap: wrap;
  `}
`;

interface OwnProps {
  values: JobAttributeValue[];
  viewMode?: string;
  filterAttributes?: boolean;
}

type Props = OwnProps;

const JobAttributes: FunctionComponent<Props> = ({ values, viewMode, filterAttributes = true }) => {
  const adminOnlyJobAttributes = useCanAdminOnlyJobAttributes();
  const sorted = useMemo<JobAttributeValue[]>(() => {
    const filteredAttributes = values.filter((jobAttributeValue: JobAttributeValue) =>
      filterAttributes ? displayJobAttribute(jobAttributeValue, adminOnlyJobAttributes) : filterEmptyJobAttributes(jobAttributeValue, adminOnlyJobAttributes)
    );

    return sortBy(filteredAttributes, 'jobAttribute.label');
  }, [values, filterAttributes, adminOnlyJobAttributes]);

  return (
    <Wrapper $viewMode={viewMode}>
      {sorted.map(attributeValue => (
        <Grid key={attributeValue.id} container spacing={1}>
          <Grid item><strong>{attributeValue.jobAttribute?.label}:</strong></Grid>
          {attributeValue.jobAttribute?.fieldType === 'file_upload' ? (
            <Grid item xs>
              <span>
                <a href={deserializeAttributeValue(attributeValue.value)} target="_blank" rel="noreferrer">Open File</a>
              </span>
            </Grid>
          ) : (
            <Grid item xs><span>{deserializeAttributeValue(attributeValue.value)}</span></Grid>
          )}
        </Grid>
      ))}
    </Wrapper>
  );
};

export default JobAttributes;
