import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { MaterialStatus } from './types';

const fontLato = [
  "'Lato'",
  'sans-serif',
].join(',');

export const fonts = {
  lato: fontLato,
};

export const colors = {
  lightBlue: '#3C75F1',
  blue: '#203D7C',
  darkBlue: '#15274F',
  yellow: '#FFC900',
  darkYellow: '#EAA307',
  grey: '#9D9D9D',
  lightGrey: '#ebebeb',
  lightGreyAlpha: '#ebebeb80',
  mediumGrey: '#eeeeee',
  offWhiteBlue: '#deebff',
  white: '#ffffff',
  black: '#000000',
  inputGray: '#707070',
  red: '#FF0000',
  green: '#93ce62',
}

// Create a theme instance.
let theme = createMuiTheme({
  colors,
  fonts,
  headerOffset: '4rem',
  breakpoints: {
    custom: {
      mobileSm: '320px',
      mobileMd: '400px',
      mobileLg: '480px',
    }
  },
  palette: {
    primary: {
      main: colors.white,
    },
    secondary: {
      main: colors.yellow,
    },
    error: {
      main: colors.yellow,
    },
    background: {
      default: colors.white,
    },
    text: {
      // The most important text.
      primary: colors.black,
      // Secondary text.
      secondary: 'rgba(0, 0, 0, 0.54)',
      // Disabled text have even lower visual prominence.
      disabled: 'rgba(0, 0, 0, 0.38)',
      // Text hints.
      hint: colors.grey,
    }
  },
  typography: {
    fontFamily: fonts.lato,
    fontFamilySecondary: fonts.lato,
    h1: {
      fontFamily: fonts.lato,
      fontSize: '2rem',
      fontWeight: '600',
      fontStretch: 'condensed',
      fontStyle: 'normal',
      lineHeight: '1.33',
      letterSpacing: 'normal',
    },
    h2: {
      fontFamily: fonts.lato,
      fontSize: '2rem',
      fontWeight: '600',
      fontStretch: 'condensed',
      fontStyle: 'normal',
      lineHeight: '1.33',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily: fonts.lato,
      fontSize: '1.875rem',
      fontWeight: '600',
      color: colors.darkBlue,
    },
    h4: {
      fontFamily: fonts.lato,
      fontSize: '1.5rem',
      fontWeight: '600',
      fontStretch: 'condensed',
      fontStyle: 'normal',
      color: colors.darkBlue,
      lineHeight: '1.31',
      letterSpacing: 'normal',
    },
    h5: {
      fontFamily: fonts.lato,
      fontWeight: '600',
      fontSize: '1.3rem',
      color: colors.darkBlue,
    },
    h6: {
      fontFamily: fonts.lato,
      fontWeight: '600',
      fontSize: '1.2rem',
      color: colors.darkBlue,
    },
    p: {
      fontWeight: '400',
    }
  },
  boxShadow: {
    light: `0 2px 4px ${colors.grey}`,
    heavy: `2px 0px 8px 2px ${colors.grey}`,
  },
  borderRadius: '20px',
  spacing: factor => `${0.5 * factor}rem`,
});

// Implement theme overrides separately so that we can use some of the theme helper methods and variables.
theme = createMuiTheme(theme, {
  app: {
    calendar: {
      gridColor: colors.lightGreyAlpha,
      columnBgColor: colors.white,
      columnBgColorDraggingOver: 'lightblue',
    },
    resourceColor: {
      unassignedColor: colors.grey,
      defaultColor: colors.grey,
      cardEdgeWidth: '5%',
      borderRadius: '0.75rem',
    },
    jobCard: {
      borderColor: colors.lightGrey,
      boxShadow: '0 1px 2px 0 rgb(9 30 66 / 25%)',
      marginBottom: '0.4rem',
      backgroundColor: colors.white,
      backgroundColorHover: colors.lightGreyAlpha,
      backgroundColorSelected: colors.offWhiteBlue,
    },
    materialStatus: {
      [MaterialStatus.NONE_IN]: colors.red,
      [MaterialStatus.SOME_IN]: colors.darkYellow,
      [MaterialStatus.ALL_IN]: colors.green,
    },
  },
  overrides: {
    MuiContainer: {
      maxWidthSm: {
        maxWidth: '1080px' // defined from UI direction
      },
      maxWidthMd: {
        maxWidth: '1080px' // defined from UI direction
      },
      maxWidthLg: {
        maxWidth: '1280px', // defined from UI direction - keeping custom for now because I'm expecting this to change.
      },
      maxWidthXl: {
        maxWidth: '1380px', // defined from UI direction
      }
    },
    MuiTabs: {
      indicator: {
        height: '1px',
        color: colors.lightBlue,
      }
    },
    MuiTab: {
      textColorInherit: {
        color: 'rgba(0, 0, 0, 0.5)',
        '&$selected': {
          color: colors.black,
          fontWeight: 700
        }
      }
    },
    MuiButton: {
      /* Styles applied to the root element. */
      root: {
        fontFamily: fonts.lato,
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.31',
        '& svg': {
          transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&$disabled': {
          '& svg': {
            fill: 'rgba(0, 0, 0, 0.26)',
          }
        },
      },
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: {
        borderRadius: '1.25rem',
        padding: '0.5rem 3rem',
        lineHeight: '1.31',
        boxShadow: theme.boxShadow,
        fontWeight: 'bold',
        backgroundColor: colors.white,
        color: colors.inputGray,
        border: `solid ${colors.inputGray} 3px`,
        '&:hover': {
          backgroundColor: colors.inputGray,
          color: colors.white,
          border: `solid ${colors.inputGray} 3px`,
          '& svg': {
            fill: 'currentColor',
      
          },
        },
        [theme.breakpoints.down("md")]: {
          padding: '0.5rem 1.5rem',
        },
        [theme.breakpoints.down("sm")]: {
          padding: '0.5rem 0.5rem',
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
      outlinedPrimary: {
        backgroundColor: colors.white,
        color: colors.lightBlue,
        border: `solid ${colors.lightBlue} 3px`,
        '&:hover': {
          backgroundColor: colors.lightBlue,
          color: colors.white,
          border: `solid ${colors.lightBlue} 3px`,
          '& svg': {
            fill: colors.white,
          },
        }
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
      outlinedSecondary: {
        backgroundColor: colors.white,
        color: colors.darkYellow,
        border: `solid ${colors.darkYellow} 3px`,
        '&:hover': {
          backgroundColor: colors.darkYellow,
          color: colors.white,
          '& svg': {
            fill: colors.white,
          },
        }
      },
      /* Styles applied to the root element if `variant="contained"`. */
      contained: {
        textTransform: 'none',
        borderRadius: '1.25rem',
        padding: '0.5rem 3rem',
        lineHeight: '1.31',
        boxShadow: theme.boxShadow,
        fontWeight: 'bold',
        '& svg': {
          transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&$disabled': {
          '& svg': {
            fill: 'rgba(0, 0, 0, 0.26)',
          }
        },
        '&:hover': {
          '& svg': {
            fill: colors.white,
          },
        },
        [theme.breakpoints.down("md")]: {
          padding: '0.5rem 1.5rem',
        },
        [theme.breakpoints.down("sm")]: {
          padding: '0.5rem 0.5rem',
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
      containedPrimary: {
        color: colors.white,
        backgroundColor: colors.blue,
        '& svg': {
          transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&$disabled': {
          '& svg': {
            fill: 'rgba(0, 0, 0, 0.26)',
          }
        },
        '&:hover': {
          backgroundColor: colors.darkBlue,
          '& svg': {
            fill: colors.white,
          },
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
      containedSecondary: {
        backgroundColor: colors.darkYellow,
        color: colors.white,
        fontWeight: 'bold',
        '& svg': {
          color: colors.white,
        },
        '&:hover': {
          color: colors.white,
          '& svg': {
            fill: colors.white,
          },
        },
      },
      /* Styles applied to the root element if `variant="text"`. */
      text: {
        backgroundColor: 'unset',
        color: colors.black,
        boxShadow: 'unset',
        borderRadius: 'unset',
        padding: '0',
        '& svg': {
          fill: colors.black,
        },
        '&:hover': {
          backgroundColor: 'unset',
          color: colors.inputGray,
          '& svg': {
            fill: colors.inputGray,
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
      textPrimary: {
        color: colors.darkBlue,
        fontWeight: 'bold',
        '& svg': {
          fill: colors.darkBlue,
        },
        '&:hover': {
          color: colors.lightBlue,
          '& svg': {
            fill: colors.lightBlue,
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
      textSecondary: {
        color: colors.yellow,
        '& svg': {
          fill: colors.yellow,
        },
        '&:hover': {
          color: colors.darkYellow,
          '& svg': {
            fill: colors.darkYellow,
          },
        },
      },
      /* Styles applied to the root element if `size="small"` and `variant="outlined"`. */
      outlinedSizeSmall: {
        padding: '3px 9px',
      },
      /* Styles applied to the root element if `size="large"` and `variant="outlined"`. */
      outlinedSizeLarge: {
        padding: '26px 90px',
        fontSize: '1.25rem',
        lineHeight: '1.31',
      },
      /* Styles applied to the root element if `size="small"` and `variant="contained"`. */
      containedSizeSmall: {
        padding: '4px 10px',
      },
      /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
      containedSizeLarge: {
        padding: '26px 90px',
        fontSize: '1.25rem',
      },
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: colors.grey,
        }
      }
    },
    MuiOutlinedInput: {
      input: {
        '&::placeholder': {
          color: colors.grey,
        }
      }
    },
    // Global overrides
    MuiCssBaseline: {
      '@global': {
        // Global CSS styles should be applied here. See: https://material-ui.com/customization/globals/#global-css
        '@font-face': [fonts.lato],
        html: {
          WebkitFontSmoothing: 'auto',
        },

        p: {
          fontFamily: fonts.lato,
          fontSize: '1rem',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: '1.5',
          letterSpacing: 'normal',
        },

        /*
          This will hide the focus indicator if the element receives focus via the mouse,
          but it will still show up on keyboard focus.
        */
        '.MuiLink-root:focus:not(.Mui-focusVisible)': {
          outline: 'none',
        },
      },
    },
  }
});

// Automate setup of responsive font sizes.
// Info: https://material-ui.com/customization/typography/#responsive-font-sizes
theme = responsiveFontSizes(theme);

export default theme;
