import { gql } from '@apollo/client';

export const SEARCH_JOBS_EVENTS_BY_TEXT = gql`
  query searchJobsEventsByText($search: String) {
    searchJobsEventsByText(search: $search) {
      __typename
      ... on Job {
        id
        jobStartDate: startDate
        jobEndDate: endDate
        recurring
        notes
        jobNumber
        createdAt
        updatedAt
        jobType {
          id
          name
        }
        trade {
          id
          name
        }
        assignedResource: calendarResource {
          id
          name
          displayColor
        }
        warehouse {
          id
          name
        }
        jobStatuses {
          id
          name
          iconCode
          iconExtraClasses
        }
        jobAttributeValues {
          id
          value
          jobAttribute {
            id
            label
            displayOnCard
            adminOnly
            fieldType
            specialBehavior
            specialBehaviorOptions
          }
        }
        propertyAddress {
          id
          address
          plan
          builder {
            id
            name
          }
          subdivision {
            id
            name
            projectManagers {
              id
              fullName
            }
          }
          projectManagers {
            id
            fullName
          }
        }
        createdBy {
          id
          email
          firstName
          lastName
        }
        updatedBy {
          id
          email
          firstName
          lastName
        }
      }
      ... on Event {
        id
        name
        startDate
        endDate
        recurring
        notes
        allResourcesEvent
        createdAt
        updatedAt
        calendarResource {
          id
          name
          displayColor
        }
        createdBy {
          id
          email
          firstName
          lastName
        }
        updatedBy {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
