import styled from 'styled-components';
import { TextField as MuiTextField } from 'formik-material-ui';
import { theme } from "styled-tools";

const TextField = styled(MuiTextField)`
  padding: 0.5rem;
  color: ${theme('colors.darkBlue')};
  font-weight: bold;
  border: solid 1px ${theme('colors.lightGrey')};
  border-radius: 4px;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export default TextField;
