interface GraphqlResource {
  __typename?: string,
}

export interface SuccessResult {
  success: boolean;
  errors?: [ValidationError];
}

export interface ValidationError {
  value?: string;
  msg: string;
  param?: string;
  location?: string;
}

export interface CalendarResource extends GraphqlResource {
  id: number,
  name: string,
  isActive?: boolean,
  displayColor?: string,
  jobType?: JobType,
  trade?: Trade,
  jobs?: Job[],
  events?: Event[],
  users?: User[],
}

export interface Event extends GraphqlResource {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  recurring?: string;
  notes?: string;
  allResourcesEvent: boolean;
  calendarResource: CalendarResource;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: User;
  updatedBy?: User;
  versionHistory?: VersionHistory;
}

export interface Job extends GraphqlResource {
  id: number,
  name?: string,
  startDate?: string,
  endDate?: string,
  recurring?: string,
  jobNumber?: string,
  notes?: string,
  jobType?: JobType,
  trade?: Trade,
  warehouse?: Warehouse,
  calendarResource?: CalendarResource,
  propertyAddress?: PropertyAddress,
  jobAttributeValues?: JobAttributeValue[],
  jobStatuses?: JobStatus[],
  createdAt?: string;
  updatedAt?: string;
  createdBy?: User;
  updatedBy?: User;
  versionHistory?: VersionHistory
  jobStartDate?: string,
  jobEndDate?: string,
  assignedResource?: CalendarResource,
}

export interface SearchResult {
  id: number;
  startDate: string;
  formattedStartDate: string;
  propertyAddress?: string;
  address?: string;
  resourceDisplayColor: string;
  resourceName? :string;
  warehouseName?: string;
  projectManagers: ProjectManager[];
  additionalNotesStatus?: JobStatus;
  additionalNotes?: string;
  refNum?: string;
  epo?: string;
  builderProcessed?: string;
  complete?: string;
  paid?: string;
  result: Job|Event;
}

export interface JobAttribute extends GraphqlResource {
  id: number;
  label?: string;
  required?: boolean;
  displayOnCard: boolean;
  readOnly: boolean;
  adminOnly: boolean;
  fieldType?: string;
  fieldOptions?: string;
  specialBehavior?: string;
  specialBehaviorOptions?: string;
  restrictedPermission?: Permission;
  editOnlyPermission?: Permission;
  jobTypes: [JobType];
}

export interface Permission extends GraphqlResource {
  id: number;
  slug: string;
}

export interface JobAttributeValue extends GraphqlResource {
  id: number,
  value: string,
  jobAttribute?: JobAttribute,
  trade?: Trade,
}

export interface JobType extends GraphqlResource {
  id: number,
  name: string,
}

export interface JobStatus extends GraphqlResource {
  id: number,
  name: string,
  description?: string,
  iconCode: string,
  iconExtraClasses?: string,
  statusCode?: string,
  disableManualSelection: boolean,
}

export interface ProjectManager extends GraphqlResource {
  id: number;
  firstName?: string;
  lastName?: string;
  fullName: string;
  email?: string;
  phoneNumber?: string;
}

export interface PropertyAddress extends GraphqlResource {
  id: number,
  address: string,
  builder: Builder,
  subdivision?: Subdivision,
  projectManagers?: ProjectManager[],
  jobs?: Job[],
  jobNumber?: string,
  plan?: string,
}

export interface QuickFilter extends GraphqlResource {
  id: number;
  name: string;
  jobTypes?: JobType[];
  trades?: Trade[];
  warehouses?: Warehouse[];
}

export interface Trade extends GraphqlResource {
  id: number,
  name: string,
  sortOrder?: number,
  jobTypes?: JobType[],
  jobAttributes?: JobAttribute[],
}

export interface User extends GraphqlResource {
  id: number,
  email?: string,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  installerContent?: string,
}

export interface VersionHistory extends GraphqlResource {
  id: number,
  resourceType?: string,
  resourceId?: string,
  field?: string
  previousValue?: string
  newValue?: string
  modifiedAt?: string
  modifiedBy?: User
}

export interface Warehouse extends GraphqlResource {
  id: number,
  name: string,
  address?: string,
}

export interface Builder extends GraphqlResource {
  id: number,
  name: string,
}

export interface Subdivision extends GraphqlResource {
  id: number,
  name: string,
  projectManagers: ProjectManager[],
  checkedJobAttributes?: JobAttribute[],
}

export interface CustomView extends GraphqlResource {
  id: number;
  name: string;
  isGlobal: boolean;
  isDefault: boolean;
  filters?: ViewFilters;
}

export interface ViewFilters extends GraphqlResource {
  installTrades: number[];
  warrantyTrades: number[];
  warehouses: number[];
  projectManagers: number[];
  jobStatuses: number[];
  jobAttributes: number[];
  builders: number[];
}

export enum MaterialStatus {
  NONE_IN = 'NONE_IN',
  SOME_IN = 'SOME_IN',
  ALL_IN = 'ALL_IN',
}

export enum JobAttributeSpecialBehavior {
  MATERIAL_STATUS = 'material_status',
  JOB_STATUS_WHEN_SET = 'job_status_when_set',
}
