import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import { DateTime } from 'luxon';
import { ordinalSuffixOf } from 'utils/functions';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
dayjs.extend(isBetween);

export const TIMEZONE = 'America/Denver';

// Set default timezone for dayjs to use Mountain Time.
dayjs.tz.setDefault(TIMEZONE);

export function dateGMT(date?: Date|string|null, keepLocalTime = false) {
  return dayjs(date ?? new Date()).tz(TIMEZONE, keepLocalTime);
}

export function formatGMT(arg1?: Date|String|null, arg2?: String|undefined): string {
  const date = (arguments[0] instanceof Date) ? arguments[0] : new Date();
  const format = (typeof arguments[0] === 'string') ? arguments[0] : arguments[1];
  return dayjs.tz(date, TIMEZONE).format(format ?? 'MM/DD/YY');
}

export function displayDate(dateString: string) {
  const date = luxonDT(dateString);
  return `${date.toFormat('EEEE, MMMM')} ${ordinalSuffixOf(date.day)}`;
}

export function luxonDT(date?: Date|string|null, keepLocalTime = false): DateTime {
  if (!date) {
    return DateTime.now().setZone(TIMEZONE, { keepLocalTime });
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date).setZone(TIMEZONE, { keepLocalTime });
  }

  return DateTime.fromISO(date).setZone(TIMEZONE, { keepLocalTime });
}

export function formatLuxon(arg1?: Date|String|null, arg2?: String|undefined): string {
  const date = (arguments[0] instanceof Date) ? arguments[0] : new Date();
  const format = (typeof arguments[0] === 'string') ? arguments[0] : arguments[1];
  return DateTime.fromJSDate(date).setZone(TIMEZONE).toFormat(format ?? 'MM/dd/yy');
}
