import React, { ChangeEvent, ChangeEventHandler, useContext, useState } from 'react';
import AddressField from 'components/Forms/TypeAheadField/AddressField';
import AddressInfoSection from './AddressInfoSection';
import CreateJobForm from './CreateJobForm';
import styled from 'styled-components/macro';
import { Button, Typography } from '@material-ui/core';
import { CREATE_JOBS } from './CreateJobForm/graphql';
import { CreateJobContext } from 'contexts/ScheduleJobsContext';
import { CalendarContext, CalendarDispatchContext } from 'contexts/CalendarContext';
import { setCacheUpdate } from 'contexts/CalendarContext/reducer';
import { Job } from '../../types';
import { luxonDT } from 'utils/dates';
import { jobMatchesFilters, serializeAttributeValue } from 'utils/jobUtils';
import { theme } from 'styled-tools';
import { useFormik } from 'formik';
import { useAuthToken, useCanAdminOnlyJobAttributes, useCanCreatePropertyAddresses, useIsAdmin } from 'config/auth';
import { gql, useMutation } from '@apollo/client';
import { useSearchState } from '../Forms/TypeAheadField';

interface ScheduleJobProps {
  toggleSlideOut: Function,
}

interface ScheduleJobSectionProps {
  justifyContent?: string,
}

const ScheduleJobContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4.3125rem;

  ${props => props.theme.breakpoints.down('sm')} {
    padding-bottom: 5.5rem;
  }
`;

const ScheduleJobHeader = styled.div`
  padding: 1rem;
  box-shadow: ${theme('boxShadow.light')};
  background: ${theme('colors.white')};
`;

export const ScheduleJobSection = styled.div<ScheduleJobSectionProps>`
  display: flex;
  padding: 0.5rem 1rem;
  width: 100%;
  border-bottom: 1px solid ${theme('colors.grey')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-basis: 55%;
  margin-right: 0.75rem;
`;

const ActionContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${theme('colors.grey')};
  border-bottom: 1px solid ${theme('colors.grey')};
  justify-content: flex-end;
  padding: 1rem;
  background-color: ${theme('colors.white')};

  button:first-of-type {
    margin-right:0.5rem;
  }
  button:last-of-type {
    margin-left:0.5rem;
  }
`;

const ErrorMessage = styled.p`
  color: ${theme('colors.red')};
  margin: 0;
  margin-top: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.25rem;
`;

const CreateAddressLink = styled.a`
  align-self: center;
`;

interface JobInput {
  name?: string,
  startDate?: string,
  endDate?: string,
  jobNumber?: string,
  notes?: string,
  propertyAddress: number|undefined,
  jobType: number|undefined,
  trade: number|undefined,
  warehouse?: number,
  calendarResource?: number,
  jobAttributeValues?: any[], // TODO: type this proper
  jobStatuses?: number[],
  recurring?: string,
  isRecurring?: boolean,
}

function ScheduleJob({ toggleSlideOut }: ScheduleJobProps) {
  const { searchFilter, searchValue, handleOptionSelected, onSearchChange, setSearchValue, setSearchFilter } = useSearchState('');
  const { clearData, address, setAddress, jobTypes } = useContext(CreateJobContext);
  const state = useContext(CalendarContext);
  const dispatch = useContext(CalendarDispatchContext);
  const isAdmin = useIsAdmin();
  const adminOnlyJobAttributes = useCanAdminOnlyJobAttributes();
  const [authToken] = useAuthToken();
  const canCreatePropertyAddresses = useCanCreatePropertyAddresses();
  const { startDate, endDate, filters } = state;

  const defaultJobType = jobTypes.find(jobType => jobType.name === 'Install');
  const warrantyJobType = jobTypes.find(jobType => jobType.name === 'Warranty');
  const [alert, setAlert] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createJobs] = useMutation(CREATE_JOBS, {
    onError: (err) => {
      setIsSubmitting(false);
      setAlert('Invalid Input: Please update the form and try again.');
    },
    update: (cache, { data: { createJobs } }) => {
      setIsSubmitting(false);
      const filteredJobs = createJobs.jobs.filter((job: Job) =>
        jobMatchesFilters(job, startDate, endDate, filters, defaultJobType, warrantyJobType)
      ).map((job: Job) => cache.writeFragment({
          data: job,
          fragment: gql`
            fragment NewJob on Job {
              id
              name
              startDate
              endDate
              recurring
              notes
              jobNumber
              createdAt
              updatedAt
              jobType {
                id
                name
              }
              trade {
                id
                name
              }
              calendarResource {
                id
                name
              }
              warehouse {
                id
                name
              }
              jobStatuses {
                id
                name
                iconCode
                iconExtraClasses
              }
              jobAttributeValues {
                id
                value
                jobAttribute {
                  id
                  label
                  displayOnCard
                  adminOnly
                  fieldType
                  specialBehavior
                  specialBehaviorOptions
                }
              }
              propertyAddress {
                id
                address
                builder {
                  id
                  name
                }
                subdivision {
                  id
                  name
                }
              }
              createdBy {
                id
                email
                firstName
                lastName
              }
              updatedBy {
                id
                email
                firstName
                lastName
              }
            }
          `
        })
      );

      if (filteredJobs.length > 0) {
        dispatch(setCacheUpdate(true));
      }

      cache.modify({
        fields: {
          searchJobs: (existingJobs = []) => [...existingJobs, ...filteredJobs]
        }
      });
    }
  });

  const handleFinishedScheduling = () => {
    toggleSlideOut && toggleSlideOut();
    clearData();
    setIsSubmitting(false);
    formik.setFieldValue('jobs', []);
    setSearchValue('');
    setSearchFilter('');
  }

  const scheduleNewJob = () => {
    let existingJobs: JobInput[] = [...formik.values.jobs];

    const newJob: JobInput = {
      propertyAddress: address?.id,
      isRecurring: false,
      startDate: undefined,
      endDate: undefined,
      jobType: defaultJobType?.id,
      trade: undefined
    };

    existingJobs.push(newJob);
    formik.setFieldValue('jobs', existingJobs);
  }

  const updateFormikValue = (fieldName: string, index: number, value: any) => {
    formik.setFieldValue(`jobs[${index}].${fieldName}`, value);
  }

  const handleFormikChange: ChangeEventHandler = (event: ChangeEvent) => {
    formik.handleChange(event);
  }

  const cancelJob = (index: number) => {
    let existingJobs: JobInput[] = [...formik.values.jobs];
    existingJobs.splice(index, 1);
    formik.setFieldValue('jobs', existingJobs);
  }

  const formik = useFormik({
    initialValues: { jobs: [] },
    // TODO: individual field validation will be added in follow up ticket
    onSubmit: async (values: any, {resetForm}) => {
      setIsSubmitting(true);
      let jobs: JobInput[] = [];

      values.jobs.forEach((job: JobInput) => {
        const jobAttributeValues: any[] = [];
        job?.jobAttributeValues?.forEach((val: any, index) => {
          if (typeof val !== "undefined") {
            jobAttributeValues.push({ jobAttribute: index, value: serializeAttributeValue(val) });
          }
        });

        delete job.isRecurring;
        jobs.push({
          ...job,
          startDate: luxonDT(job.startDate).startOf('day').toUTC().toISO(),
          endDate: luxonDT(job.endDate ?? job.startDate).startOf('day').toUTC().toISO(),
          jobAttributeValues: jobAttributeValues,
          jobNumber: address?.jobNumber
        });
      });

      if (jobs.length > 0) {
        const parsedValues = { input: jobs };
        const { data, errors } = await createJobs({ variables: parsedValues });
        const mutationRes = data.createJobs;

        if (mutationRes.success) {
          resetForm({ values: { jobs: [] } });
          setAlert('');
          handleFinishedScheduling();
        } else {
          setAlert('Invalid input:' + errors);
        }
      }

      setIsSubmitting(false);
    },
  });

  return (
    <ScheduleJobContainer>
      <ScheduleJobHeader>
        <Typography variant="h4">Select Address</Typography>
      </ScheduleJobHeader>
      <ScheduleJobSection>
        <AddressWrapper>
          <AddressField
            searchFilter={searchFilter}
            searchValue={searchValue}
            handleOptionSelected={handleOptionSelected}
            onSearchChange={onSearchChange}
            setAddress={setAddress}
          />
        </AddressWrapper>
        {!address && isAdmin && canCreatePropertyAddresses && (
          <CreateAddressLink href={`${process.env.REACT_APP_ADMIN_URL}/resources/PropertyAddress/actions/new?token=${authToken}`} target='_blank'>
            Create new address
          </CreateAddressLink>
        )}
        {address &&
          <AddressInfoSection address={address} disabled={false} />
        }
      </ScheduleJobSection>
      {address &&
        <form onSubmit={formik.handleSubmit} autoComplete='off'>
          {alert && <ErrorMessage>{alert}</ErrorMessage>}
          {formik.values.jobs.map((value: Job, index: number) => (
            <ScheduleJobSection key={`schedule_job_${index}`}>
              <CreateJobForm
                index={index}
                job={value}
                checkedJobAttributes={address?.subdivision?.checkedJobAttributes}
                adminOnlyJobAttributes={adminOnlyJobAttributes}
                onCancel={cancelJob}
                onFieldUpdate={updateFormikValue}
                onFieldChange={handleFormikChange}
              />
            </ScheduleJobSection>
          ))}
          <ScheduleJobSection>
            <Button
              onClick={scheduleNewJob}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Schedule A Job For this Address
            </Button>
          </ScheduleJobSection>
          <ActionContainer>
            <Button variant='text' onClick={handleFinishedScheduling}>CANCEL THESE JOBS</Button>
            <Button variant='contained' color='secondary' type='submit' disabled={isSubmitting}>FINISHED SCHEDULING</Button>
          </ActionContainer>
        </form>
      }
    </ScheduleJobContainer>
  );
}

export default ScheduleJob;
