import React from 'react';
import styled from 'styled-components/macro';
import { CalendarResource } from 'types';
import { theme } from 'styled-tools';

interface CalendarResourceInfoProps {
  calendarResource: CalendarResource,
}

const BuilderContainer = styled.div`
  display: flex;
  flex-basis: 45%;
  flex-direction: column;
  margin-left: 0.75rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const InfoLabel = styled.span`
  font-weight: bold;
`;

const InfoDisplay = styled.span`
  padding: 0.5rem;
  color: ${theme('colors.darkBlue')};
  font-weight: bold;
  border: solid 1px ${theme('colors.lightGrey')};
  border-radius: 4px;
  margin-bottom: 0.5rem;
`;

function CalendarResourceInfoSection({ calendarResource }: CalendarResourceInfoProps) {
  return (
    <BuilderContainer>
      <InfoWrapper>
        <InfoLabel>Trade</InfoLabel>
        <InfoDisplay>{calendarResource.jobType?.name} {calendarResource.trade?.name}</InfoDisplay>
      </InfoWrapper>
    </BuilderContainer>
  );
}

export default CalendarResourceInfoSection;
