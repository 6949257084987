import DropdownResult from 'routes/Search/DropdownResult';
import Icon from 'components/Icon';
import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Input as BaseInput, InputAdornment, useMediaQuery, useTheme } from '@material-ui/core';
import { openEventDetail, openJobDetail } from 'contexts/CalendarContext/reducer';
import { useFocusEventHandler } from './index';
import { theme } from 'styled-tools';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { CalendarDispatchContext } from 'contexts/CalendarContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSearchContext } from 'contexts/SearchContext';

interface SearchInputProps {
  placeholder?: string;
  closePopover?: Function | undefined;
}

const Input = styled(BaseInput)`
    padding: 0 1.5rem;
    color: ${theme('colors.inputGray')};
    align-items: left;
    ${props => props.theme.breakpoints.up('md')} {
      border-radius: ${theme('borderRadius')};
      box-shadow: ${theme('boxShadow.light')};
    }
    & .MuiInputAdornment-positionEnd {
      margin-left: 2rem;
      ${props => props.theme.breakpoints.up('md')} {
        margin-left: 1rem;
      }
    }
`;

export const JobDropDown = styled.ul`
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: 1;
  overflow: auto;
  border: 1px solid ${theme('colors.lightGrey')};
  border-radius: 4px;
  background-color: ${theme('colors.white')};
  max-height: 35.5rem;
  & li[data-focus="true"] {
    background-color: ${theme('colors.lightGrey')};
    cursor: pointer;
  }
  & li {
    cursor: pointer;
    padding: 0.5rem;
    &:active,
    &:hover {
      background-color: #2977f5;
      color: ${theme('colors.lightGrey')};
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${theme('colors.lightGrey')};
    }
  }
`;

function SearchInput({ closePopover, placeholder = 'What are you looking for?' }: SearchInputProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    loading,
    searchValue,
    onSearchChange,
    searchResults,
    setSearchFilter,
    handleOptionSelected,
  } = useSearchContext();
  const dispatch = useContext(CalendarDispatchContext);
  const { isFocused, setIsFocused, handleFocusEvent } = useFocusEventHandler();
  const location = useLocation();
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);

  const handleSearchInputFocusEvent = (e: any) => {
    handleFocusEvent(e);

    if (e.type === 'focus' && searchValue !== '') {
      setRedirect(false);
      setSearchFilter(searchValue);
    }
  }

  const handleItemClick = useCallback((item: any) => {
    if (item.propertyAddress) {
      handleOptionSelected(item.propertyAddress.address);
      dispatch(openJobDetail(item));
    } else {
      handleOptionSelected(item.name);
      dispatch(openEventDetail(item));
    }

    setIsFocused(false);

    // Close parent popover if in mobile
    if (closePopover) {
      closePopover();
    }

    if (location.pathname !== '/') {
      history.push('/');
    }
  }, [setIsFocused, closePopover, location.pathname, handleOptionSelected, dispatch, history]);

  const handleKeyPress = (e: any) => {
    if (e.target.id === 'search-field') {
      setRedirect((e.key === 'Enter'));
    }
  }

  return (
    <div>
      <Input
        id='search-field'
        type='text'
        placeholder={!isMobile ? placeholder : ''}
        endAdornment={
          <InputAdornment position='end'>
            {loading
              ? (<CircularProgress size='0.75em' color='inherit'/>)
              : (<Icon icon='search' size={0.75}/>)
            }
          </InputAdornment>
        }
        disableUnderline={true}
        value={searchValue}
        onChange={onSearchChange}
        onFocus={handleSearchInputFocusEvent}
        onBlur={handleFocusEvent}
        onKeyDown={handleKeyPress}
        autoComplete='off'
      />
      {(!loading && isFocused && location.pathname !== '/search/results') &&
        <JobDropDown>
          {searchResults.map((option: any) => (
            <li onFocus={handleFocusEvent} onClick={() => handleItemClick(option)} key={option.id} tabIndex={0}>
              <DropdownResult result={option} />
            </li>
          ))}
        </JobDropDown>
      }
      {!loading && (redirect || location.pathname === '/search/results') &&
        <Redirect to={{
          pathname: '/search/results',
        }}/>
      }
    </div>
  );
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
}

export default SearchInput;
