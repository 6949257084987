import React, { FunctionComponent } from 'react';
import { MaterialStatus } from 'types';
import styled from 'styled-components/macro';
import Tooltip from '@material-ui/core/Tooltip';

type StatusCircleProps = {
  $status: MaterialStatus,
};

const StatusCircle = styled.div<StatusCircleProps>`
  width: 1.25em;
  height: 1.25em;
  display: block;
  background-color: transparent;
  border: 0.25rem solid ${({ theme, $status }) => theme.app.materialStatus[$status] || theme.app.materialStatus[MaterialStatus.NONE_IN]};
  border-radius: 100%;
`;

const progressValues = {
  [MaterialStatus.NONE_IN]: 0,
  [MaterialStatus.SOME_IN]: 50,
  [MaterialStatus.ALL_IN]: 100,
}

const progressLabels = {
  [MaterialStatus.NONE_IN]: 'None In',
  [MaterialStatus.SOME_IN]: 'Some In',
  [MaterialStatus.ALL_IN]: 'All In',
};

interface OwnProps {
  status: MaterialStatus;
  className?: string;
}

type Props = OwnProps;

const MaterialStatusIndicator: FunctionComponent<Props> = ({ status, className }) => {
  return (
    <Tooltip title={`Material Status: ${progressLabels[status] || progressLabels[MaterialStatus.NONE_IN]}`} arrow>
      <StatusCircle $status={status} role='progressbar' aria-valuenow={progressValues[status] || 0} className={className} />
    </Tooltip>
  );
};

export default MaterialStatusIndicator;
