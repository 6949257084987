import React, { createContext, useCallback, useContext, useState } from 'react';
import { debounce } from 'lodash';
import { useQuery } from '@apollo/client';
import { SEARCH_JOBS_EVENTS_BY_TEXT } from 'graphql/queries/SearchJobsEventsByText';
import { Event, Job } from 'types';

interface SearchState {
  initial: boolean,
  loading: boolean,
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  resetSearch: () => void,
  searchFilter: string,
  setSearchFilter: (value: string) => void
  searchValue: string,
  setSearchValue: (value: string) => void,
  handleOptionSelected: (option: string) => void,
  searchResults: (Job|Event)[],
}

const contextInitialState : SearchState = {
  initial: true,
  loading: false,
  searchFilter: '',
  searchValue: '',
  setSearchFilter: (_: string) => {},
  setSearchValue: (_: string) => {},
  handleOptionSelected: (_: string) => {},
  onSearchChange: (_: React.ChangeEvent<HTMLInputElement>) => {},
  resetSearch: () => {},
  searchResults: [],
}

export const SearchContext = createContext<SearchState>(contextInitialState);

interface SearchProviderProps {
  children: React.ReactNode,
  initialValue: string,
}

export function SearchProvider({children, initialValue}: SearchProviderProps) {
  const [initial, setInitial] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [searchValue, setSearchValue] = useState(initialValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchFilter = useCallback(debounce(setSearchFilter, 500), []);
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitial(false);
    const { value } = e.target;
    setSearchValue(value);
    debounceSetSearchFilter(value);
  };

  const resetSearch = () => {
    setSearchFilter('');
    setSearchValue('');
    setInitial(true);
  }

  const { loading, data } = useQuery(SEARCH_JOBS_EVENTS_BY_TEXT, {
    variables: { search: searchFilter },
  });

  const parseResponse = (data: any): (Job|Event)[] => {
    return data ? data.searchJobsEventsByText.map((option: any) => {
      return option;
    }) : [];
  }

  const handleOptionSelected = (selected = '') => {
    setSearchFilter('');
    setSearchValue(selected);
  }

  const searchResults = parseResponse(data);

  return <SearchContext.Provider value={{
    initial,
    loading,
    searchValue,
    setSearchValue,
    searchFilter,
    setSearchFilter,
    onSearchChange,
    resetSearch,
    searchResults,
    handleOptionSelected,
  }}>
    {children}
  </SearchContext.Provider>
}

export const useSearchContext = () => useContext(SearchContext);
