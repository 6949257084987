import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ErrorContainer = styled.div`
  width: 100%;
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function ErrorPage() {
  return (
    <ErrorContainer>
      <p>
        There was a problem while trying to load the page.
      </p>
      <Link to='/'>Return to Calendar</Link>
    </ErrorContainer>
  );
}

export default ErrorPage;