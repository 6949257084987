import React, { FunctionComponent } from 'react';
import ScheduleEvent from 'components/ScheduleEvent';
import SlideOut, { SlideOutProps } from 'components/SlideOut';
import { CreateJobProvider } from 'contexts/ScheduleJobsContext';

interface OwnProps extends SlideOutProps {
  toggleSlideOut: Function;
}

type Props = OwnProps;

const ScheduleEventSlideOut: FunctionComponent<Props> = ({ slideOutOpen, toggleSlideOut, ...props }) => {
  return (
    <SlideOut slideOutOpen={slideOutOpen} preload {...props}>
      <CreateJobProvider>
        <ScheduleEvent toggleSlideOut={toggleSlideOut} />
      </CreateJobProvider>
    </SlideOut>
  );
};

export default ScheduleEventSlideOut;
