import React, { useCallback, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useCalendarDispatchContext } from 'contexts/CalendarContext';
import { closeDisclaimer } from 'contexts/CalendarContext/reducer';

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 200;
`;

const DisclaimerText = styled(Typography)`
  font-size: 0.75rem;
  padding: 1rem;
`;

const CloseButton = styled(IconButton)`
  float: right;
  display: block;

  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

const Footer = () => {
  const dispatch = useCalendarDispatchContext();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleClose = useCallback(
    () => {
      setIsOpen(false);
      dispatch(closeDisclaimer());
    },
    [dispatch],
  );

  if (!isOpen) {
    return null;
  }

  return (
    <StyledFooter>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      <DisclaimerText variant='body2'>
        By accessing the CFC Scheduling Software, you agree to use this software in accordance with all terms and
        conditions, including any terms further explained in the Subcontractor Agreement. This software shall not be
        accessed for any purpose other than to receive project assignments. You will be paid for tasks considered
        complete by Colorado Floor Company personnel. Any project details that require additional compensation or
        discussion should be emailed to <a href='mailto:subcontractor@coloradofloor.com'>subcontractor@coloradofloor.com</a>.
      </DisclaimerText>
    </StyledFooter>
  );
};

export default Footer;
