import React, { FunctionComponent } from 'react';
import { formatLuxon } from 'utils/dates';
import { Job } from 'types';

interface OwnProps {
  result: Job;
}

type Props = OwnProps;

const DropdownResult: FunctionComponent<Props> = ({ result }) => {

  return (
    <>
      { result && result.propertyAddress ? (
        <>
          <div>
            {result.propertyAddress.address}
            {result.propertyAddress.plan && <span>, {result.propertyAddress.plan}</span>}
            {result.propertyAddress.subdivision && <span>, {result.propertyAddress.subdivision.name}</span>}
          </div>
          <span>{result?.jobType?.name}&nbsp;{result?.trade?.name}</span>
          <span> - {result?.assignedResource?.name || 'UNASSIGNED'}</span>
          { result.jobStartDate && (
            <span> - {formatLuxon(new Date(result.jobStartDate), 'MM-dd-yyyy')}</span>
          )}
        </>
      ) : (
        <>
          <div>{result.name}</div>
          <span>{result?.calendarResource?.name || 'UNASSIGNED'}</span>
          { result.startDate && (
            <span> - {formatLuxon(new Date(result.startDate), 'MM-dd-yyy')}</span>
          )}
        </>
      )}
    </>
  );
};

export default DropdownResult;
