import { gql } from '@apollo/client';
import { CalendarEventFields } from 'graphql/fragments/CalendarEventFields';

export const CREATE_EVENTS = gql`
  mutation createEvents(
    $input: [CreateEventInput!]!
  ) {
    createEvents(input: $input) {
      success
      errors {
        value
        msg
        param
        location
      }
      events {
        ...CalendarEventFields
      }
    }

  }
  ${CalendarEventFields}
`;
