import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';
import { theme } from 'styled-tools';

export interface SlideOutProps {
  slideOutOpen: boolean,
  preload?: boolean,
  noDomElement?: boolean,
}

type SlideOutContainerProps = {
  isMobile?: boolean,
};

const SlideOutDomObject = styled.div`
  position: relative;
  transition: flex-basis 0.5s ease-out;
  flex-basis: 0;
  left: -35vw;

  &.is_open {
    transition: flex-basis 0.5s;
    flex-basis: 30vw;
    left: 0;

    ${props => props.theme.breakpoints.down('md')} {
      flex-basis: 100vw;
    }
  }
`;

const SlideOutContainer = styled.div<SlideOutContainerProps>`
  width: 30vw;
  background-color: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow.heavy')};
  position: fixed;
  top: ${props => (props.isMobile ? '56px' : '64px')};
  z-index: 9999;
  height: calc(100% - ${props => (props.isMobile ? '56px' : '64px')});
  overflow: scroll;
  transition: 0.7s;
  left: -35vw;

  &.is_open {
    left: 0;
    transition: 0.5s;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: 100vw;
    left: -105vw;
  }
`;

const SlideOut: FunctionComponent<SlideOutProps> = ({ slideOutOpen, preload = false, noDomElement = false, children }) => (
  <>
    {!noDomElement && <SlideOutDomObject className={slideOutOpen ? 'is_open' : ''}/>}
    <SlideOutContainer className={slideOutOpen ? 'is_open' : ''} isMobile={isMobile}>
      {(slideOutOpen || preload) && children}
    </SlideOutContainer>
  </>
);

export default SlideOut;
