import React from 'react';
import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';
import { theme } from 'styled-tools';
import { gql, useQuery } from "@apollo/client";
import { DropDown, useFocusEventHandler } from './index';

interface AutocompleteFieldProps {
  label?: string,
  setCalendarResource?: Function,
  searchFilter: string,
  searchValue: string,
  handleOptionSelected: (option: string) => void,
  onSearchChange: (e: any) => void,
}

const InputWrapper = styled.div`
  width: 100%;

  label {
    display: block;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: solid 1px ${theme('colors.inputGray')};
    font-size: ${(isMobile ? '1rem' : 'inherit')};

    &:focus-visible {
      border-color: ${theme('colors.lightBlue')};
      outline: none;
    }
  }
`;

const CalendarResourceDropDown = styled(DropDown)`
  max-height: 200px;
`;

const EventCalendarResourceFieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const GET_CALENDAR_RESOURCES = gql`
  query searchCalendarResourcesByName($search: String, $limit: Int) {
    searchCalendarResourcesByName(search: $search, limit: $limit) {
      id
      name
      jobType {
        id
        name
      }
      trade {
        id
        name
      }
    }
  }
`;

function EventCalendarResourceField(
  {
    label,
    setCalendarResource,
    searchFilter,
    searchValue,
    handleOptionSelected,
    onSearchChange
  }: AutocompleteFieldProps) {
  const { isFocused, setIsFocused, handleFocusEvent } = useFocusEventHandler();

  const { loading, data } = useQuery(GET_CALENDAR_RESOURCES, {
    variables: { search: searchFilter, limit: 0 },
  });

  const handleClick = (option: any) => {
    handleOptionSelected(option.name);
    setIsFocused(false);
    setCalendarResource && setCalendarResource(option);
  }

  return (
    <EventCalendarResourceFieldWrapper>
      <InputWrapper>
        {label && <label >{label}</label>}
        <input
          id='event-calendar-resource-field'
          value={searchValue}
          onChange={onSearchChange}
          onFocus={handleFocusEvent}
          onBlur={isMobile ? handleFocusEvent : undefined}
          autoComplete='off'
        />
      </InputWrapper>
      {(!loading && isFocused) &&
        <CalendarResourceDropDown>
          {data && data.searchCalendarResourcesByName.map((option: any) => (
            <li onClick={() => handleClick(option)} key={option.id} tabIndex={0}>{option.name}</li>
          ))}
        </CalendarResourceDropDown>
      }
    </EventCalendarResourceFieldWrapper>
  );
}

export default EventCalendarResourceField;
