import React, { FunctionComponent } from 'react';
import { Job, JobAttributeSpecialBehavior, MaterialStatus } from 'types';
import MaterialStatusIndicator from 'components/MaterialStatusIndicator';
import { deserializeAttributeValue } from 'utils/jobUtils';

interface OwnProps {
  job: Job;
  className?: string;
}

type Props = OwnProps;

const JobMaterialStatus: FunctionComponent<Props> = ({ job, className }) => {
  // Check if the job has material status attribute.
  const statusAttr = job.jobAttributeValues?.find(
    attrValue => attrValue.jobAttribute?.specialBehavior === JobAttributeSpecialBehavior.MATERIAL_STATUS
  );


  const attrValue = deserializeAttributeValue(statusAttr?.value);

  if (!statusAttr || !attrValue?.length) {
    return null;
  }

  const status: MaterialStatus = (MaterialStatus as any)[attrValue];

  return <MaterialStatusIndicator status={status || MaterialStatus.NONE_IN} className={className} />;
};

export default JobMaterialStatus;
