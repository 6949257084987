import React from 'react';
import PropTypes from 'prop-types';
import icons, { iconsInterface } from 'assets/icons';

interface IconProps {
  size?: number,
  color?: string,
  icon: keyof iconsInterface,
  className?: string,
  style?: any,
  alt?: string,
}

function Icon({ size = 1, color = 'currentColor', icon, className = '', style = {}, alt }: IconProps) {
  const IconComponent: any = icons[icon];

  return (
    <IconComponent
      height={`${size}em`}
      width={`${size}em`}
      className={className}
      style={style}
      fill={color}
      alt={alt}
    />
  );
}

Icon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.oneOf([
    'search',
    'plus',
    'remove',
    'chevronUp',
    'chevronDown',
    'chevronLeft',
    'chevronRight',
  ]).isRequired
}

export default Icon;
