import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import PhoneNumber from 'components/PhoneNumber';

interface AddressInfoProps {
  address: any,
  disabled?: boolean,
}

interface DisabledProps {
  disabled?: boolean,
}

interface ProjectManager {
  id: number,
  firstName?: string,
  lastName?: string,
  fullName?: string,
  phoneNumber?: string,
  email?: string,
}

const BuilderContainer = styled.div<DisabledProps>`
  display: flex;
  flex-basis: 45%;
  flex-direction: column;
  margin-left: ${props => props.disabled ? '0' : '0.75rem'};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const InfoLabel = styled.span`
  font-weight: bold;
`;

const InfoDisplay = styled.span<DisabledProps>`
  padding: 0.5rem;
  color: ${theme('colors.darkBlue')};
  font-weight: bold;
  border: solid 1px ${theme('colors.lightGrey')};
  border-radius: 4px;
  margin-bottom: 0.5rem;
  background-color: ${props => props.disabled ? props.theme.colors.lightGreyAlpha : 'inherit'};
`;

function AddressInfoSection({ address, disabled }: AddressInfoProps) {
  const projectManagers = (address?.projectManagers && address?.projectManagers.length > 0) ? address?.projectManagers : (address?.subdivision?.projectManagers || []);

  return (
    <BuilderContainer disabled={disabled}>
      <InfoWrapper>
        <InfoLabel>Builder</InfoLabel>
        <InfoDisplay disabled={disabled}>{address?.builder?.name ?? '-'}</InfoDisplay>
      </InfoWrapper>

      <InfoWrapper>
        <InfoLabel>Builder Subdivision</InfoLabel>
        <InfoDisplay disabled={disabled}>{address?.subdivision?.name ?? '-'}</InfoDisplay>
      </InfoWrapper>

      {address?.plan && (
        <InfoWrapper>
          <InfoLabel>Plan:</InfoLabel>
          <InfoDisplay disabled={disabled}>{address?.plan}</InfoDisplay>
        </InfoWrapper>
      )}

      <InfoWrapper>
        <InfoLabel>Project Manager</InfoLabel>
        {projectManagers.length > 0 ? (
          projectManagers.map((pm: ProjectManager) => (
            <InfoDisplay key={pm.id} disabled={disabled}>
              {disabled ? (
                <>
                  {pm.fullName}
                </>
              ) : (
                <>
                  {pm.firstName} {pm.lastName}
                  {pm.phoneNumber && <><br /><small><PhoneNumber value={pm.phoneNumber} /></small></>}
                </>
              )}
            </InfoDisplay>
          ))
        ) : (
          <InfoDisplay disabled={disabled}>-</InfoDisplay>
        )}
      </InfoWrapper>
      {address?.jobNumber && (
        <InfoWrapper>
          <InfoLabel>Job Number:</InfoLabel>
          <InfoDisplay disabled={disabled}>{address?.jobNumber}</InfoDisplay>
        </InfoWrapper>
      )}
    </BuilderContainer>
  );
}

export default AddressInfoSection;
