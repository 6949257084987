import React, { FunctionComponent } from 'react';
import SlideOut, { SlideOutProps } from 'components/SlideOut';
import ScheduleJob from 'components/ScheduleJob';
import { CreateJobProvider } from 'contexts/ScheduleJobsContext';

interface OwnProps extends SlideOutProps {
  toggleSlideOut: Function;
}

type Props = OwnProps;

const ScheduleJobSlideOut: FunctionComponent<Props> = ({ slideOutOpen, toggleSlideOut, ...props }) => {

  return (
    <SlideOut slideOutOpen={slideOutOpen} preload {...props}>
      <CreateJobProvider>
        <ScheduleJob toggleSlideOut={toggleSlideOut} />
      </CreateJobProvider>
    </SlideOut>
  );
};

export default ScheduleJobSlideOut;
