import { gql } from '@apollo/client';

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $resetToken: String!,
    $plainPassword: String!,
    $plainPasswordConfirm: String!
  ) {
    changePassword(input: {
      resetToken: $resetToken,
      plainPassword: $plainPassword,
      plainPasswordConfirm: $plainPasswordConfirm
    }) {
      token
    }
  }
`;

export const CHECK_RESET_TOKEN = gql`
  query checkResetToken($resetToken: String!) {
    checkResetToken(resetToken: $resetToken) {
      success
      errors {
        value
        msg
        param
        location
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
      errors {
        value
        msg
        param
        location
      }
    }
  }
`;
