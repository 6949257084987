import { gql } from '@apollo/client';
import { CalendarJobFields } from 'graphql/fragments/CalendarJobFields';

export const CREATE_JOBS = gql`
  mutation createJobs(
    $input: [CreateJobInput!]!
  ) {
    createJobs(input: $input) {
      success
      errors {
        value
        msg
        param
        location
      }
      jobs {
        ...CalendarJobFields
      }
    }

  }
  ${CalendarJobFields}
`;
