import { useQuery, gql } from "@apollo/client";

const GET_WAREHOUSES = gql`
  query getWarehouses {
    listWarehouses {
      id
      name
      address
    }
  }
`;

function useGetWarehouses(options?: any) {
  return useQuery(GET_WAREHOUSES, options);
}

export default useGetWarehouses;
