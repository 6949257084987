import React, { FunctionComponent } from 'react';
import { Job } from 'types';
import JobStatusIcon from 'components/JobStatusIcon';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > .MuiIcon-root {
    margin: 0.25rem;
  }
`;

interface OwnProps {
  job: Job,
}

type Props = OwnProps;

const JobStatuses: FunctionComponent<Props> = ({ job }) => {
  return (
    <Wrapper>
      {job.jobStatuses?.map(jobStatus => (
        <JobStatusIcon key={jobStatus.id} notes={job.notes} status={jobStatus} />
      ))}
    </Wrapper>
  );
};

export default JobStatuses;
