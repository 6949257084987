import {
  FormHeader,
  FormBody,
  LoginContainer,
  LoginWrapper,
  LoginHeader,
  LoginPaper,
  LoginBody,
} from '../Login/styles';
import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import ChangePasswordForm from './components/ChangePasswordForm';
import ForgotPasswordEmailForm from './components/ForgotPasswordEmailForm';
import logoImg from '../Login/assets/logo.jpg';
import logoIconImg from '../Login/assets/logo-mountains.png';
import { CHECK_RESET_TOKEN } from './graphql';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

function ForgotPassword () {
  const [alert, setAlert] = useState('');
  const [tokenConfirmed, setTokenConfirmed] = useState(false);
  const { resetToken } = useParams<{ resetToken: string }>();
  const Form = !tokenConfirmed ? ForgotPasswordEmailForm : ChangePasswordForm;

  useQuery(CHECK_RESET_TOKEN, {
    skip: !resetToken,
    variables: useParams(),
    onCompleted: data => {
      const queryRes = data.checkResetToken;

      if (!queryRes.success) {
        setAlert('Invalid input: ' + queryRes.errors[0].msg);
      }

      setTokenConfirmed(queryRes.success);
    },
    onError: err => {
      setAlert('Invalid input: ' + err.message);
      setTokenConfirmed(false);
    },
  });

  return (
    <LoginWrapper>
      <LoginHeader>
        <img src={logoImg} alt={'The Colorado Floor Company'} />
      </LoginHeader>
      <LoginBody>
        <LoginContainer maxWidth='xs'>
          <LoginPaper square>
            <FormHeader>
              <img src={logoIconImg} alt='Icon of mountains from part of the CFC logo' />
            </FormHeader>
            <FormBody>
              {alert && <Alert severity='error'>{alert}</Alert>}
              <Form />
            </FormBody>
          </LoginPaper>
        </LoginContainer>
      </LoginBody>
    </LoginWrapper>
  );
}

export default ForgotPassword;
