import React, {useCallback, useEffect, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components/macro';
import { Box, IconButton } from '@material-ui/core';
import { deserializeAttributeValue } from 'utils/jobUtils';
import { useDropzone } from 'react-dropzone'
import { theme } from 'styled-tools';

interface FileUploadComponent {
  id: string,
  name: string,
  onChange?: Function,
  value?: any,
  required?: boolean,
  disabled?: boolean
}

const DropZone = styled.div`
  border: 1px dashed;
  text-align: center;
  cursor: pointer;

  p {
    font-size: inherit;
  }
`;

const DisabledDropZone = styled.div`
  border: 1px dashed ${theme('colors.inputGray')};
  text-align: center;
  color: ${theme('colors.inputGray')};

  p {
    font-size: inherit;
  }
`;

const AttachedItem = styled.div`
  svg {
    width: 0.75em;
  }
`;

const HiddenRequiredInput = styled.input`
  opacity: 0;
  height: 0px;
  padding: 0px !important;
  width: 50% !important;
  position: absolute;
  left: 3rem;
`;

function FileUpload({ id, name, onChange, value: initValue, required = false, disabled = false }: FileUploadComponent) {
  const [file, setFile] = useState<null | File>(null);
  const [existingFileLink, setExistingFileLink] = useState<null | string>(initValue);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Match PDFs and any image type
    if (acceptedFiles[0] && acceptedFiles[0].type.match(/(application\/pdf|^image\/.+)/i)) {
      setFile(acceptedFiles?.[0]);
    } else {
      setFile(null);
    }
  }, []);

  const removeFile = () => {
    if (file) {
      setFile(null);

      if (!existingFileLink) {
        onChange && onChange(null);
      }
    } else if (existingFileLink) {
      setExistingFileLink(null);
      onChange && onChange(null);
    }
  }

  useEffect(() => {
    if (file) {
      let baseURL: string | ArrayBuffer | null = "";

      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;

        onChange && onChange(baseURL);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    onChange && onChange(initValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  return (
    <Box>
      {!disabled ? (
        <DropZone {...getRootProps()}>
          <input id={id} name={name} {...getInputProps()} />
          <p>Click or drag to upload <br/> image or pdf</p>
        </DropZone>
      ) : (
        <DisabledDropZone>
          <p>Click or drag to upload <br/> image or pdf</p>
        </DisabledDropZone>
      )}
      {(file || existingFileLink) &&
        <AttachedItem>
          { file && <>{file.name}</> }
          { !file && existingFileLink &&  <a href={deserializeAttributeValue(existingFileLink)} target="_blank" rel="noreferrer">Open File</a> }
          { !disabled && (
            <IconButton onClick={removeFile}>
              <CloseIcon />
            </IconButton>
          )}
        </AttachedItem>
      }
      <HiddenRequiredInput
        tabIndex={-1}
        autoComplete="off"
        value={ existingFileLink ? existingFileLink : file?.name }
        required={required}
      />
    </Box>
  );
}

export default FileUpload;
