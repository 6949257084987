import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useIsAuthenticated } from '../../config/auth';
import { RouteProps } from 'react-router';

type Props = React.PropsWithChildren<RouteProps>;

function PrivateRoute({ children, ...rest }: Props) {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Route {...rest} render={({ location }) => {
      return isAuthenticated
        ? children
        : <Redirect to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
    }} />
  )
}

export default PrivateRoute;
