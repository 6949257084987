import React, { useContext, useMemo, useEffect, ChangeEventHandler } from 'react';
import styled from 'styled-components/macro';
import { Button, Typography } from '@material-ui/core';
import { luxonDT } from 'utils/dates';
import { InputWrapper, DateField, TextArea, Select, JobAttributeField, RRuleInput } from 'components/Forms';
import { CreateJobContext } from 'contexts/ScheduleJobsContext';
import { isMobile } from 'react-device-detect';
import { JobAttribute, JobStatus, JobType, Trade } from 'types';
import CalendarResourceField from 'components/Forms/TypeAheadField/CalendarResourceField';
import dayjs from 'dayjs';

interface CreateJobFormProps {
  title?: string,
  job?: any,
  index: number,
  adminOnlyJobAttributes: boolean,
  checkedJobAttributes: JobAttribute[]|undefined,
  onCancel: Function,
  onFieldUpdate: Function,
  onFieldChange: ChangeEventHandler,
}

const ManageJobContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled.span`
  display: block;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;

  & > div {
    flex: 0 0 50%;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  button:first-of-type {
    margin-left: 0.5rem;
  }
  button:first-of-type {
    margin-right: 0.5rem;
  }
`;

const RRuleWrapper = styled.div`
  && {
    flex-basis: 100%;
  }
`;

function CreateJobForm({ title = 'Schedule a Job', job, index, checkedJobAttributes, adminOnlyJobAttributes, onCancel, onFieldUpdate, onFieldChange }: CreateJobFormProps) {
  const { jobTypes, trades, warehouses, jobStatuses } = useContext(CreateJobContext);
  const defaultJobType = jobTypes.find(x => x.name === 'Install');

  const availableTrades = useMemo(() => {
    return trades.filter(x => !!x?.jobTypes?.find(jobType => jobType.id === (job.jobType || defaultJobType?.id)));
  }, [job.jobType, defaultJobType?.id, trades]);

  const jobAttributes = useMemo(() => {
    const selectedTrade = trades.find((t) => t.id === job.trade);
    return selectedTrade?.jobAttributes?.filter(ja => !!ja?.jobTypes?.find(jobType => jobType.id === job.jobType) && (!ja.adminOnly || adminOnlyJobAttributes));
  }, [job.jobType, job.trade, trades, adminOnlyJobAttributes]);

  const jobStatusOptions = useMemo(() => {
    const manualJobStatuses = jobStatuses.filter(js => !js.disableManualSelection);
    return manualJobStatuses.map(({ id, name }: JobStatus) => ({ value: id, label: name }));
  }, [jobStatuses]);

  // Clear out Trade field if selected trade is no longer available.
  useEffect(() => {
    if (job.trade &&
      availableTrades.length > 0 &&
      !availableTrades.find(x => x.id.toString() === job.trade.toString())
    ) {
      onFieldUpdate('trade', index, undefined);
    }
  }, [job.trade, availableTrades, index, onFieldUpdate]);

  return (
    <ManageJobContainer>
      <Typography variant='h5'>{title}</Typography>
      <SectionLabel>Trade Details</SectionLabel>
      <Section>
        <DateField
          id={`jobs[${index}].startDate`}
          name={`jobs[${index}].startDate`}
          label='Start Date **'
          value={job.startDate}
          onChange={(date) => onFieldUpdate('startDate', index, luxonDT(date, true).toJSDate().toISOString())}
          required={true}
          minDate={new Date(new Date().getFullYear(), 0, 1)}
        />

        {job.isRecurring && (
          <DateField
            id={`jobs[${index}].endDate`}
            name={`jobs[${index}].endDate`}
            label='End Date **'
            value={job.endDate}
            onChange={(date) => onFieldUpdate('endDate', index, luxonDT(date, true).toJSDate().toISOString())}
            required={job.isRecurring}
            minDate={new Date(new Date().getFullYear(), 0, 1)}
          />
        )}

        <InputWrapper isCheckbox flexBasis='100%'>
          <label>
            Recurring
            <input
              id={`jobs[${index}].isRecurring`}
              name={`jobs[${index}].isRecurring`}
              type='checkbox'
              checked={job.isRecurring}
              onChange={(e) => {
                // If unchecked, clear out the recurring field.
                if (!e.target.checked) {
                  onFieldUpdate('recurring', index, null);
                }
                onFieldUpdate('isRecurring', index, e.target.checked);
              }}
            />
          </label>
        </InputWrapper>

        {job.isRecurring && (
          <RRuleWrapper>
            <RRuleInput
              startDate={job.startDate ? dayjs(job.startDate).toDate() : new Date()}
              endDate={job.endDate ? dayjs(job.endDate).toDate() : undefined}
              value={job.recurring}
              onChange={(value: string) => onFieldUpdate('recurring', index, value)}
            />
          </RRuleWrapper>
        )}

        <InputWrapper>
          <label>Select Job Type</label>
          <Select
            id={`jobs[${index}].jobType`}
            name={`jobs[${index}].jobType`}
            value={job.jobType || defaultJobType?.id}
            onChange={(id: number) => onFieldUpdate('jobType', index, id)}
            placeholder='Select Job Type'
            options={jobTypes.map(({ id, name }: JobType) => ({ value: id, label: name }))}
          />
        </InputWrapper>
        <InputWrapper>
          <label>Select a Trade</label>
          <Select
            id={`jobs[${index}].trade`}
            name={`jobs[${index}].trade`}
            value={job.trade}
            onChange={(id: number) => onFieldUpdate('trade', index, id)}
            placeholder='Select a Trade'
            options={availableTrades.map(({ id, name }: Trade) => ({ value: id, label: name }))}
          />
        </InputWrapper>
        <InputWrapper flexBasis='100%'>
          <label>Additional Notes</label>
          <TextArea
            id={`jobs[${index}].notes`}
            name={`jobs[${index}].notes`}
            value={job.notes || ''}
            onChange={onFieldChange}
        />
        </InputWrapper>

        {jobAttributes && jobAttributes.map((jobAttribute: JobAttribute) => (
          <JobAttributeField
            key={jobAttribute.id}
            jobAttribute={jobAttribute}
            jobAttributeValues={job.jobAttributeValues}
            checkedJobAttributes={checkedJobAttributes}
            jobIndex={index}
            onFieldChange={onFieldChange}
            onFieldUpdate={onFieldUpdate}
          />
        ))}
      </Section>

      <SectionLabel>Delivery/Install Details</SectionLabel>
      <Section>
        <InputWrapper flexBasis={isMobile ? '100%': ''}>
          <label>Assigning To</label>
          <CalendarResourceField
            jobType={job.jobType}
            trade={job.trade}
            warehouseId={job.warehouse}
            value={job.calendarResource}
            onChange={(id: number) => onFieldUpdate('calendarResource', index, id)}
          />
        </InputWrapper>
        <InputWrapper flexBasis={isMobile ? '100%': ''}>
          <label>Warehouse Location **</label>
          <Select
            id={`jobs[${index}].warehouse`}
            name={`jobs[${index}].warehouse`}
            value={job.warehouse}
            placeholder='Select a Warehouse'
            required={true}
            onChange={(id: number) => onFieldUpdate('warehouse', index, id)}
            options={warehouses.map(({ id, name }: Trade) => ({ value: id, label: name }))}
          />
        </InputWrapper>
        <InputWrapper flexBasis='100%'>
          <label>Job Statuses</label>
          <Select
            id={`jobs[${index}].jobStatuses`}
            name={`jobs[${index}].jobStatuses`}
            value={job.jobStatuses}
            onChange={(ids: (number[]|string[])) => {
              onFieldUpdate('jobStatuses', index, ids)
            }}
            options={jobStatusOptions}
            isMulti={true}
          />
        </InputWrapper>
      </Section>
      <ButtonWrapper>
        <Button variant='outlined' onClick={() => onCancel(index)}>Cancel Job</Button>
      </ButtonWrapper>
    </ManageJobContainer>
  );
}

export default CreateJobForm;
