import React, { Component, ErrorInfo, ReactNode } from "react";
import { Notifier } from '@airbrake/browser';
import { ReactCookieProps, withCookies } from 'react-cookie';
import ErrorPage from 'routes/ErrorPage';
import { UserToken } from '../../config/auth';
import jwtDecode from "jwt-decode";

interface Props extends ReactCookieProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  airbrake?: Notifier | null;
}

const TOKEN_NAME = process.env.REACT_APP_AUTH_TOKEN_COOKIE_NAME || 'cfc.auth_token';

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    airbrake: process.env.REACT_APP_AIRBRAKE_ID && process.env.REACT_APP_AIRBRAKE_KEY ? new Notifier({
      projectId: parseInt(process.env.REACT_APP_AIRBRAKE_ID, 10),
      projectKey: process.env.REACT_APP_AIRBRAKE_KEY,
      environment: process.env.REACT_APP_ENVIRONMENT || 'local',
    }) : null
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public constructor(props: Props) {
    super(props);
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Send error to Airbrake if enabled, otherwise log error to console.
    if (this.state.airbrake) {
      const token = this.props.cookies?.get(TOKEN_NAME);
      const user: UserToken | null = token ? jwtDecode(token) : null;
      this.state.airbrake.notify({
        error,
        params: { errorInfo },
        context: { user },
      });
    }
  }

  public render() {
    if (this.state.hasError) {
      // Render fallback UI, serving up the error page.

      return <ErrorPage />
    }

    return this.props.children;
  }
}

export default withCookies(ErrorBoundary);