import React from 'react';
import styled from 'styled-components/macro';
import { useDragLayer } from 'react-dnd'
import JobCard from 'routes/Calendar/components/JobCard';

const PreviewLayer = styled('div')`
  position: fixed;
  pointer-events: none;
  touch-action: none;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const PreviewJobCard = styled(JobCard)`
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  border: 1px solid #eee;
  transform: rotate(3deg);

  &:before {
    width: 5%;
  }
`;

function DragLayer() {
  const { item, isDragging, currentOffset } = useDragLayer(
    monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  if (!isDragging || !currentOffset) {
    return null
  }
  
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return (
    <PreviewLayer style={{ transform, WebkitTransform: transform }}>
      <div style={{ width: '250px' }}>
        <PreviewJobCard job={item} calendarResource={item.calendarResource} />
      </div>
    </PreviewLayer>
  )
}

export default DragLayer;
