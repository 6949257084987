import { useQuery, gql } from "@apollo/client";

const GET_JOB_TYPES = gql`
  query getJobTypes {
    listJobTypes {
      id
      name
    }
  }
`;

function useGetJobTypes(options?: any) {
  return useQuery(GET_JOB_TYPES, options);
}

export default useGetJobTypes;
