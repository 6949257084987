import React, { ChangeEventHandler, useEffect, useState } from 'react';
import FileUpload from '../FileUpload';
import { JobAttribute } from 'types';
import { TextArea, Select, InputWrapper } from 'components/Forms';
import { useCanEditJobAttribute } from 'config/auth';

interface JobAttributeFieldProps {
  jobAttribute: JobAttribute,
  jobIndex?: number,
  jobAttributeValues: any[]|undefined,
  checkedJobAttributes: JobAttribute[]|undefined,
  onFieldUpdate: Function,
  onFieldChange: ChangeEventHandler,
  isMultipleJobs?: boolean,
}

interface Option {
  label: string,
  value: string,
}

function buildOptions(optionsString = ''): Option[] {
  return optionsString.split('\n').map((opStr: string) => {
    const [label, value] = opStr.split(':');
    return { label, value: value ? value.replace(/\r/, '') : '' };
  });
}

function JobAttributeField({ jobAttribute, jobIndex, jobAttributeValues, checkedJobAttributes, onFieldUpdate, onFieldChange, isMultipleJobs = true }: JobAttributeFieldProps) {
  const identifier = isMultipleJobs ? `jobs[${jobIndex}].jobAttributeValues[${jobAttribute.id}]` : `jobAttributeValues[${jobAttribute.id}]`;
  const isCheckbox = jobAttribute.fieldType === 'checkbox';
  const [isCheckedJobAttribute, setIsCheckedJobAttribute] = useState(false);
  const canEditJobAttribute = useCanEditJobAttribute(jobAttribute.restrictedPermission, jobAttribute.editOnlyPermission) && !jobAttribute.readOnly;

  // Sanitize the field value if it's a string.
  let fieldValue = (jobAttributeValues && jobAttributeValues[jobAttribute.id]) || '';
  if (typeof fieldValue === 'string') {
    fieldValue = fieldValue.replace(/\r/, '');
  }

  const baseProps = {
    id: identifier,
    name: identifier,
    value: fieldValue,
    onChange: onFieldChange,
  };

  useEffect(() => {
    if (checkedJobAttributes && isCheckbox) {
      const checkedJobAttributeMatches = checkedJobAttributes?.filter((checkedJobAttribute: JobAttribute) =>
        checkedJobAttribute.id === jobAttribute.id
      );

      if (checkedJobAttributeMatches.length > 0) {
        onFieldUpdate(`jobAttributeValues[${jobAttribute.id}]`, jobIndex, true);
        setIsCheckedJobAttribute(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedJobAttributes, jobAttribute, isCheckbox]);

  return (
    <InputWrapper isCheckbox={isCheckbox}>
      {(jobAttribute.label && !isCheckbox) &&
        <label>
          {jobAttribute.label} {(jobAttribute.required) && <>**</>}
        </label>
      }
      {(jobAttribute.fieldType === 'text' || jobAttribute.fieldType === 'number') &&
        <input {...baseProps} required={jobAttribute.required} type={jobAttribute.fieldType} disabled={!canEditJobAttribute} step={jobAttribute.fieldType === 'number' ? 'any' : undefined} />
      }
      {jobAttribute.fieldType === 'textarea' &&
        <TextArea {...baseProps} required={jobAttribute.required} disabled={!canEditJobAttribute} />
      }
      {jobAttribute.fieldType === 'file_upload' &&
        <FileUpload
          {...baseProps}
          required={jobAttribute.required}
          disabled={!canEditJobAttribute}
          onChange={(val: any) => onFieldUpdate(`jobAttributeValues[${jobAttribute.id}]`, jobIndex, val)}
        />
      }
      {isCheckbox &&
        <label>
          {jobAttribute.label ?? ''}
          { isCheckedJobAttribute ? (
            <input {...baseProps} type='checkbox' checked={true} disabled={true} />
          ) : (
            <input {...baseProps} type='checkbox' checked={baseProps.value} disabled={!canEditJobAttribute} />
          )}
        </label>
      }
      {(jobAttribute.fieldType === 'select' || jobAttribute.fieldType === 'multiselect') &&
        <Select
          {...baseProps}
          required={jobAttribute.required}
          disabled={!canEditJobAttribute}
          onChange={(val: any) => onFieldUpdate(`jobAttributeValues[${jobAttribute.id}]`, jobIndex, val)}
          options={buildOptions(jobAttribute?.fieldOptions)}
          isMulti={jobAttribute.fieldType === 'multiselect'}
        />
      }
    </InputWrapper>
  );
}

export default JobAttributeField;
