import { useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { debounce } from 'lodash';

export interface Option {
  value: number,
  label: string,
}

export const DropDown = styled.ul`
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  overflow: auto;
  border: 1px solid ${theme('colors.lightGrey')};
  border-radius: 4px;
  background-color: ${theme('colors.white')};

  & li[data-focus="true"] {
    background-color: ${theme('colors.lightGrey')};
    cursor: pointer;
  }

  & li {
    cursor: pointer;
    padding: 0.5rem;

    &:active {
      background-color: #2977f5;
      color: ${theme('colors.lightGrey')};
    }
  }
`;

/**
 * Logic used for handling search input, with debounce for request reduction
 * @param value starting search string value
 */
export function useSearchState(value = '') {
  const [searchFilter, setSearchFilter] = useState('');
  const [searchValue, setSearchValue] = useState(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchFilter = useCallback(debounce(setSearchFilter, 500), []);
  const onSearchChange = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
    debounceSetSearchFilter(value);
  };

  const handleOptionSelected = (selected = '') => {
    setSearchFilter('');
    setSearchValue(selected);
  }

  return {
    searchFilter,
    searchValue,
    handleOptionSelected,
    onSearchChange,
    setSearchValue,
    setSearchFilter
  };
}

/**
 * Logic needed to track focus of select input for drop down display
 */
export function useFocusEventHandler() {
  const [isFocused, setIsFocused] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFocusChange = useCallback(debounce(setIsFocused, 50), []);
  const handleFocusEvent = (e: any) => {
    if (e.type === 'focus') {
      handleFocusChange(true);
    } else if (e.type === 'blur') {
      handleFocusChange(false);
    }
  }

  return { isFocused, setIsFocused, handleFocusEvent }
}

