import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import Cookies from 'js-cookie';

const TOKEN_NAME = process.env.REACT_APP_AUTH_TOKEN_COOKIE_NAME || 'cfc.auth_token';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GATEWAY_API_URL + '/graphql',
  headers: {},
});

const authMiddleware = () => {
  return new ApolloLink((operation, forward) => {
    const authToken = Cookies.get(TOKEN_NAME);
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  })
};

export const useAppApolloClient = () => {
  return new ApolloClient({
    uri: process.env.REACT_APP_GATEWAY_API_URL + '/graphql',
    defaultOptions: {},
    cache: new InMemoryCache(),
    link: authMiddleware().concat(httpLink),
    connectToDevTools: true,
  });
}
