import { useQuery, gql } from "@apollo/client";

const GET_JOB_STATUSES = gql`
  query getJobStatuses {
    listJobStatuses {
      id
      name
      description
      statusCode
      disableManualSelection
      iconCode
    }
  }
`;

function useGetJobStatuses(options?: any) {
  return useQuery(GET_JOB_STATUSES, options);
}

export default useGetJobStatuses;
