import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';

const sendPageView = (location: Location) => {
  // @ts-ignore
  if (window.dataLayer) {
    // Wrap in a setTimeout so that the pageview event will
    // hopefully occur after react-helmet updates the page title.
    // Without this, the page title shown in GA will often be the previous page.
    // https://github.com/nfl/react-helmet/issues/189#issuecomment-336292754
    setTimeout(() => {
      // @ts-ignore
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: location,
        },
      });
    }, 0);
  }
}

interface OwnProps {
  children: JSX.Element;
}

type Props = OwnProps;

function AnalyticsListener({ children }: Props) {
  const history = useHistory();

  useEffect(() => {
    sendPageView(history.location);
    return history.listen(sendPageView);
  }, [history]);

  return children;
}

export default AnalyticsListener;
