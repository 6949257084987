import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import BaseJobMaterialStatus from 'components/JobMaterialStatus';
import Typography from '@material-ui/core/Typography';
import { VIEW_MODES } from 'contexts/CalendarContext';

interface ResourceColorProps {
  $resourceColor?: string;
  $unassigned?: boolean;
  viewMode?: string;
}

type CardProps = ResourceColorProps;

interface CardBodyProps {
  $selected?: boolean;
}

export const CardBody = styled.div<CardBodyProps>`
  padding: 0.5rem;
  flex: 1 1 auto;
  border: 1px solid ${({ theme }) => theme.app.jobCard.borderColor};
  background-color: ${({ theme, $selected }) => $selected ? theme.app.jobCard.backgroundColorSelected : theme.app.jobCard.backgroundColor};
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div<CardProps>`
  display: flex;
  flex-wrap: nowrap;
  padding-left: ${({ theme }) => theme.app.resourceColor.cardEdgeWidth};
  position: relative;
  margin-bottom: ${({ theme }) => theme.app.jobCard.marginBottom};
  border-top-left-radius: ${({ theme }) => theme.app.resourceColor.borderRadius};
  border-bottom-left-radius: ${({ theme }) => theme.app.resourceColor.borderRadius};
  margin-left: 0.4rem;
  margin-right: 0.4rem;

  &:hover {
    cursor: pointer;

    ${CardBody} {
      background-color: ${({ theme }) => theme.app.jobCard.backgroundColorHover};
    }
  }

  // Resource color indicator on left-edge.
  &:before {
    display: block;
    content: '';
    width: ${({ theme }) => theme.app.resourceColor.cardEdgeWidth};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: ${({ theme }) => theme.app.resourceColor.borderRadius};
    border-bottom-left-radius: ${({ theme }) => theme.app.resourceColor.borderRadius};
    box-shadow: ${({ theme }) => theme.app.jobCard.boxShadow};

    ${ifProp(
  '$unassigned',
  css<ResourceColorProps>`
        background-color: ${({ theme }) => theme.app.resourceColor.unassignedColor};
        border-color: ${({ theme }) => theme.app.resourceColor.unassignedColor};
      `,
  css<ResourceColorProps>`
        background-color: ${({ theme, $resourceColor }) => $resourceColor ? $resourceColor : theme.app.resourceColor.defaultColor};
        border-color: ${({ theme, $resourceColor }) => $resourceColor ? $resourceColor : theme.app.resourceColor.defaultColor};
      `
)}
  }

  ${({ viewMode }) => viewMode === VIEW_MODES.TEN_DAY && css<ResourceColorProps>`
    ${CardBody} {
      overflow: hidden;
    }

    ${CardTitle} {
      flex-wrap: wrap;
    }
  `}
`;

export const JobMaterialStatus = styled(BaseJobMaterialStatus)`
  flex: 0 0 auto;
  margin-left: 0.25rem;
`;

export const JobAddress = styled(Typography).attrs({ variant: 'h3' })`
  font-size: 0.8rem;
`;

export const Subdivision = styled(Typography).attrs({ variant: 'h4' })`
  font-size: 0.8rem;
`;

export const JobNumber = styled(Typography).attrs({ variant: 'h5' })`
  font-size: 0.8rem;
`;
